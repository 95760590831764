/* eslint-disable react-hooks/exhaustive-deps */
import styles from "./events.module.scss";
import { useNavigate } from "react-router-dom";
import { rootStore, useMst } from "../../models/root";
import { useTranslation } from "react-i18next";
import PhotoHeader from "../../components/common/photoHeader/photoHeader";
import image from "../../art/swimmer.jpg";
import { observer } from "mobx-react-lite";
import { useEffect } from "react";

function Events({usePhoto = true}) {
  const { t } = useTranslation("Events");
  const navigate = useNavigate();
  const { eventsStore } = useMst();
  const { fetchEvents, loading, upcomingEvents, pastEvents } = eventsStore;
  const { language } = rootStore;

  useEffect(() => {
    fetchEvents();
  }, []);

  useEffect(() => {
    fetchEvents();
  }, [language]);

  function goToPath(path) {
    navigate(path);
  }
  if (loading) {
    return <div>Loading</div>;
  }
  return (
    <div className={styles.events}>
      {usePhoto && <PhotoHeader
        className={styles.photo}
        image={image}
        description={t("events")}
        title={t("events")}
        color="#F3F3E7"
      />}
      <div className={styles.hosting}>
        {/* <div className={styles.title}>{t("calendar")}</div>
        <div
          className={styles.link}
          role="button"
          onClick={() =>
            goToPath('/calendarEvents')
          }
        >
          {t("calendar")}
        </div> */}
        {/* <div
          className={styles.link}
          role="button"
          onClick={() =>
            goToPath("/customPage/66642cab-5cdd-4fe8-8c7d-73d7d881cb4d")
          }
        >
          {t("hostingInfo")}
        </div> */}
      </div>

      <div className={styles.upcoming}>
        <div className={styles.title}>{t("upcomingEvents")}</div>
        {upcomingEvents.map((event) => {
          let eventPath;
          if (
            event.useFirstCustomPageLinkAsContent &&
            event.customPageLinks &&
            event.customPageLinks.length > 0
          ) {
            eventPath = event.customPageLinks[0].url;
          } else {
            eventPath = `/event/${event._id}`;
          }
          return (
            <div
              key={event._id}
              role="button"
              title={t("openEvent")}
              onClick={() => goToPath(eventPath)}
              className={styles.event}
            >
              <div className={styles.description}>{event.description}</div>
              <div className={styles.date}>
                {/* {t("date", { date: event.startDate })} */}
                {event.dateandlocation}
              </div>
            </div>
          );
        })}
      </div>
      <div className={styles.past}>
        <div className={styles.title}>{t("pastEvents")}</div>
        {pastEvents.map((event) => {
          let eventPath;
          if (
            event.useFirstCustomPageLinkAsContent &&
            event.customPageLinks &&
            event.customPageLinks.length > 0
          ) {
            eventPath = event.customPageLinks[0].url;
          } else {
            eventPath = `/event/${event._id}`;
          }
          return (
            <div
              key={event._id}
              role="button"
              title={t("openEvent")}
              onClick={() => goToPath(eventPath)}
              className={styles.event}
            >
              <div className={styles.description}>{event.description}</div>
              <div className={styles.date}>
                {/* {t("date", { date: event.startDate })} */}
                {event.dateandlocation}
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
}

export default observer(Events);
