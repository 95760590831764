import { values } from "mobx";
import { types, flow, getParent } from "mobx-state-tree";
import sanityClient from "../client";

const Color = types.model({
  color: "#F3F3F7",
  backgroundColor: "#13294B",
});

const AnyJsonValue = types.union(
  types.string,
  types.number,
  types.integer,
  types.Date,
  types.boolean,
  types.map(types.late(() => AnyJsonValue)),
  types.array(types.late(() => AnyJsonValue)),
  types.undefined,
  types.null
);
const Block = types.model({
  _key: types.maybeNull(types.string),
  _type: types.maybeNull(types.string),
  marginTop: types.maybeNull(types.string),
  marginRight: types.maybeNull(types.string),
  marginBottom: types.maybeNull(types.string),
  marginLeft: types.maybeNull(types.string),
  color: types.maybeNull(types.string),
  backgroundColor: types.maybeNull(types.string),
  block: types.frozen(types.maybeNull(types.array(AnyJsonValue))),
});

const Discipline = types.model({
  key: types.maybeNull(types.string),
  _id: types.identifier,
  color: types.maybeNull(Color),
  imageUrl: types.maybeNull(types.string),
  name: types.string,
  _type: types.maybeNull(types.string),
  userPageId: types.maybeNull(types.string),
  customPageId: types.maybeNull(types.string),
  widgetType: types.maybeNull(types.string),
});

const UserFile = types.model({
  _key: types.maybeNull(types.string),
  _type: types.maybeNull(types.string),
  name: types.maybeNull(types.string),
  color: types.maybeNull(Color),
  url: types.maybeNull(types.string),
  embedd: types.maybeNull(types.boolean),
});

export const CustomPageLink = types
  .model({
    _key: types.maybeNull(types.string),
    _type: types.maybeNull(types.string),
    name: types.maybeNull(types.string),
    color: types.maybeNull(Color),
    customPageId: types.maybeNull(types.string),
    customPageShortId: types.maybeNull(types.string),
  })
  .views((self) => {
    return {
      get url() {
        if (self.customPageShortId) {
          return `/page/${self.customPageShortId}`;
        }
        return `/customPage/${self.customPageId}`;
      },
    };
  });

  export const EasyPageLink = types
  .model({
    _key: types.maybeNull(types.string),
    _type: types.maybeNull(types.string),
    name: types.maybeNull(types.string),
    color: types.maybeNull(Color),
    easyPageId: types.maybeNull(types.string),
    easyPageShortId: types.maybeNull(types.string),
  })
  .views((self) => {
    return {
      get url() {
        if (self.easyPageShortId) {
          return `/pages/${self.easyPageShortId}`;
        }
        return `/easyPage/${self.easyPageId}`;
      },
    };
  }); 

const Image = types.model({
  _key: types.maybeNull(types.string),
  _type: types.maybeNull(types.string),
  name: types.maybeNull(types.string),
  color: types.maybeNull(Color),
  url: types.maybeNull(types.string),
  embedd: types.maybeNull(types.boolean),
  showLabel: true,
  width: types.maybeNull(types.optional(types.string, "5rem")),
  height: types.maybeNull(types.string),
  linkTo: types.maybeNull(types.string),
});

const EventsList = types.model({
  _key: types.maybeNull(types.string),
  _type: types.maybeNull(types.string),
  numberOfEvents: 1,
  eventType: "upcoming",
});

const AreaRow = types.model({
  _key: types.maybeNull(types.string),
  name: types.maybeNull(types.string),
  color: types.maybeNull(types.string),
  backgroundColor: types.maybeNull(types.string),
  topMargin: types.maybeNull(types.string),
  bottomMargin: types.maybeNull(types.string),
  leftMargin: types.maybeNull(types.string),
  rightMargin: types.maybeNull(types.string),
  areaColumns: types.maybeNull(
    types.array(
      types.union(
        {
          dispatcher: (snapshot) => {
            if (snapshot && snapshot._type === "customPageLink") {
              return CustomPageLink;
            }
            if (snapshot && snapshot._type === "easyPageLink") {
              return EasyPageLink;
            }
            if (snapshot && snapshot._type === "userFile") {
              return UserFile;
            }
            if (snapshot && snapshot._type === "localeBlock") {
              return Block;
            }
            if (snapshot && snapshot._type === "userUrl") {
              return UserFile;
            }
            if (snapshot && snapshot._type === "disciplineArea") {
              return Discipline;
            }
            if (snapshot && snapshot._type === "localeImage") {
              return Image;
            }
            if (snapshot && snapshot._type === "eventsList") {
              return EventsList;
            }
            return Block;
          },
        },
        Block,
        Discipline,
        UserFile,
        CustomPageLink,
        EasyPageLink,
      )
    )
  ),
});

const Area = types.model({
  _key: types.maybeNull(types.string),
  name: types.maybeNull(types.string),
  areaRows: types.maybeNull(types.array(AreaRow)),
});

const CustomPage = types.model({
  _id: types.identifier,
  shortId: types.maybeNull(types.string),
  addToMenu: false,
  menuOrder: types.maybeNull(types.number),
  name: types.maybeNull(types.string),
  areas: types.maybeNull(types.array(Area)),
});

const CustomPageId = types.model({
  shortId: types.identifier,
  customPage: types.reference(CustomPage),
});

const CustomPages = types
  .model({
    customPages: types.map(CustomPage),
    loading: false,
    state: "done",
    customPagesIds: types.map(CustomPageId),
  })
  .views((self) => {
    return {
      get allCustomPages() {
        return values(self.customPages);
      },
      getCustomPage(customPageId) {
        return self.customPages.get(customPageId);
      },
      getCustomPageByShortId(customPageShortId) {
        const customPageId = self.customPagesIds.get(customPageShortId);
        if (customPageId) {
          return customPageId.customPage;
        }
        return null;
      },
      get addToMenuPages() {
        return self.allCustomPages
          .filter((page) => page.addToMenu)
          .sort((prev, next) => {
            if (next.menuOrder < prev.menuOrder) {
              return 1;
            }
            return -1;
          });
      },
      get navigation() {
        const navigationAfter = [
          { path: "/members", key: "members" },
          { path: "/events", key: "events" },
          { path: "/newsletters", key: "newsletters" },
        ];

        const customNavigation = self.addToMenuPages.map((customPage) => {
          return {
            path: `/customPage/${customPage._id}`,
            key: customPage.name,
          };
        });
        return customNavigation.concat(navigationAfter);
      },
    };
  })
  .actions((self) => {
    const fetchCustomPages = flow(function* () {
      // <- note the star, this is a generator function!
      self.state = "pending";
      self.loading = true;
      const language = getParent(self).language;
      try {
        // ... yield can be used in async/await style
        const customPagesArray = yield sanityClient.fetch(
          `*[_type == "customPage"]
          {
            _id,
            shortId,
            addToMenu,
            menuOrder,
            "name": name.${language},
            "areas" : areas[]
            { _key, name, 
                "areaRows": areaRows[]
                {
                  _key, name, "color": color->color, "backgroundColor": color->backgroundColor, topMargin, bottomMargin, leftMargin, rightMargin,
                  "areaColumns": areaColumns[]
                    {
                       _type == 'customPageLink' => @{_key,_type,"name":title.${language}, "color": color->{color, backgroundColor}, 
                                                       "customPageId":customPage->_id, "customPageShortId": customPage->shortId},
                      _type == 'easyPageLink' => @{_key,_type,"name":title.${language}, "color": color->{color, backgroundColor}, 
                                                       "easyPageId":easyPage->_id, "easyPageShortId": easyPage->shortId},
                      _type == 'userFile' => @{_key, _type, "name": name.${language}, "color": color->{color, backgroundColor},
                                                  "url": file.${language}.asset->url, embedd},  
                       _type == 'userUrl' => @{_key, _type, "name": label.${language}, "color": color->{color, backgroundColor},
                                                  "url": url.${language}},   
                      _type == 'localeBlock' => @{_key, _type, marginTop, marginRight, marginBottom, marginLeft, "color": color->color,
                                               "backgroundColor": color->backgroundColor, "block": ${language}[]},
                       _type == 'disciplineArea' => @{_key, _type, "widgetType": ^.widgetType[0],"spanRight": ^.spanRight, 
                                                      "spanDown": ^.spanDown, "_id": discipline->_id, "key": discipline->key, 
                                                      "color": discipline->color->{color, backgroundColor}, 
                                                      "imageUrl": discipline->image.asset->url, "name": discipline->name.${language}, 
                                                      "customPageId": discipline->customPage->_id, "userPageId": discipline->userPage->_id},
                        _type == 'localeImage' => @{_key, _type,"name": ${language}, "url": coalesce(image.asset->url, photo.${language}.asset->url), photo, 
                                                      "color": labelColor->{color, backgroundColor}, showLabel, width, height, "linkTo": linkTo[0].url.${language}},
                        _type == 'eventsList' => @{_key, _type, numberOfEvents, eventType}
                    }
                }      
            }      
          }[0...100]`
        );

        self.customPages.clear();
        self.customPagesIds.clear();
        customPagesArray.forEach((element) => {
          const customPage = self.customPages.put(element);
          if (element.shortId) {
            self.customPagesIds.put({
              shortId: element.shortId,
              customPage: customPage._id,
            });
          }
        });
        self.loading = false;
        self.state = "done";
      } catch (error) {
        // ... including try/catch error handling
        console.error("Failed to fetch custom pages", error);
        self.loading = false;
        self.state = "error";
      }
    });

    return {
      fetchCustomPages,
    };
  });

export default CustomPages;
