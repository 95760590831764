/* eslint-disable react-hooks/exhaustive-deps */
import styles from "./easyPage.module.scss";
import { useNavigate } from "react-router-dom";
import { rootStore, useMst } from "../../models/root";
import { observer } from "mobx-react-lite";
import { useEffect } from "react";
import { useParams } from "react-router-dom";
import Discipline from "../discipline/discipline";
import UserFile from "../userFile/userFile";
import { PortableText } from "@portabletext/react";
import EventsList from "../eventsList/eventsList";
import Carousel from "../common/carousel/carousel";
import YouTube from "react-youtube";
import Chats from "../chats/chats";
import Events from "../events/events";
import Newsletters from "../newsletters/newsletters";
import Webinars from "../webinars/webinars";

function EasyPage() {
  const params = useParams();
  const { easyPagesStore } = useMst();
  const navigate = useNavigate();
  const { fetchEasyPages, getEasyPage, getEasyPageByShortId, loading } =
    easyPagesStore;
  const { language } = rootStore;

  useEffect(() => {
    fetchEasyPages();
  }, []);
  useEffect(() => {
    fetchEasyPages();
  }, [language]);

  let easyPage;
  if (params.easyPageShortId) {
    easyPage = getEasyPageByShortId(params.easyPageShortId);
  } else {
    easyPage = getEasyPage(params.easyPageId);
  }

  if (!easyPage) {
    easyPage = getEasyPageByShortId("home");
  }

  if (!easyPage || loading) {
    return <div>Loading</div>;
  }

  function image(item, { color, backgroundColor }) {
    function goToPath(link) {
      if (item.easyPageShortId) {
        navigate(`/pages/${item.easyPageShortId}`);
      } else if (item.linkTo) {
        window.open(link, "_blank");
      } else {
        navigate(link);
      }
    }
    const cursor = item.linkTo || item.easyPageShortId ? "pointer" : undefined;
    let imageContainerStyle;
    if (item.setAsBackground) {
      imageContainerStyle = {"--width": item.width, "--height": item.height, "--color": color || 'black', "--background-image": `linear-gradient(to right, ${backgroundColor}, ${backgroundColor}BB, transparent), url('${item.url}')`}
    } else {
      imageContainerStyle = {};
    }

    if (item.setAsBackground) {
      return (
        <div className={styles.image}
          style={imageContainerStyle}
          onClick={() => goToPath(item.linkTo)}
        >
         
        </div>
      );
    }
    return (
      <div className={styles.image}
        style={imageContainerStyle}
      >
        <img
          className={styles.img}
          src={item.url}
          alt={item.alt}
          style={{
            "--width": `${item.width}`,
            "--height": `${item.height}`,
            "--cursor": `${cursor}`,
          }}
          onClick={() => goToPath(item.linkTo)}
        />
      </div>
    );
  }

  function siteColumn(column) {
    return (
      <div
        key={column._key}
        className={styles.column}
        style={{
          "--width": column.width,
          "--backgroundColor": column.backgroundColor,
          "--marginTop": column.topMargin,
          "--marginBottom": column.bottomMargin,
          "--marginLeft": column.leftMargin,
          "--marginRight": column.rightMargin,
          "--color": column.color,
          "--gridColumn": column.gridColumn,
          "--gridRow": column.gridRow,
        }}
      >
        <div
          className={styles.marginTop}
          // style={{ "--marginTop": column.topMargin }}
        />
        <div
          className={styles.marginBottom}
          // style={{ "--marginBottom": column.bottomMargin }}
        />
        <div
          className={styles.marginLeft}
          // style={{ "--marginLeft": column.leftMargin }}
        />
        <div
          className={styles.marginRight}
          // style={{ "--marginRight": column.rightMargin }}
        />
        <div className={styles.body}>
          {column.content.map((contentItem) => {
            const contentType = contentItem._type;

            if (contentType === "localizedImage") {
              return image(contentItem, column);
            }
            if (contentType === "localeBlock") {
              return (
                <div className={styles.portableText}>
                  <PortableText
                    key={contentItem._key}
                    value={contentItem.block}
                  />
                </div>
              );
            }

            if (
              contentType === "userFile" ||
              contentType === "userUrl" ||
              contentType === "customPageLink" ||
              contentType === "easyPageLink"
            ) {
              if (contentItem.isYouTube) {
                const videoId = contentItem.url.split("/").pop();
                const opts = { height: '100%', width: '100%'}
                return (
                  <div className={styles.youtube}>
                    <YouTube className={styles.video} videoId={videoId} opts={opts}/>
                  </div>
                );
              } else {
                return (
                  <UserFile key={contentItem._key} contentItem={contentItem} />
                );
              }
            }

            if (contentType === "eventsList") {
              return (
                <EventsList
                  key={contentItem._key}
                  color={contentItem.color}
                  backgroundColor={contentItem.backgroundColor}
                />
              );
            }
            if (contentType === "disciplineArea") {
              return (
                <Discipline
                  key={contentItem._key}
                  name={contentItem.name}
                  imageUrl={contentItem.imageUrl}
                  color={contentItem.color}
                  userPageId={contentItem.userPageId}
                  customPageId={contentItem.customPageId}
                  widgetType={contentItem.widgetType}
                />
              );
            }
            if (contentType === "separator") {
              return (
                <div
                  key={contentItem._key}
                  className={styles.separator}
                  style={{
                    "--width": contentItem.width,
                    "--height": contentItem.height,
                    "--color": contentItem.color,
                    "--backgroundColor": contentItem.backgroundColor,
                  }}
                />
              );
            }
            if (contentType === "carousel") {
              return (
                <Carousel
                  carouselId={contentItem.carouselId}
                  key={contentItem._key}
                />
              );
            }
            if (contentType === "component") {
              if (contentItem.component === 'chats') {
                return (
                  <Chats 
                    chatType='underwater'
                    audienceType='forAthletes'
                  />
                );
              }
              if (contentItem.component === 'coachesChats') {
                return (
                  <Chats 
                    chatType="underwater"
                    audienceType='forCoaches'
                  />
                );
              }
              if (contentItem.component === 'webinars') {
                return (
                  <Webinars 
                    chatType='webinar'
                    audienceType='forBoth'
                  />
                );
              }
              if (contentItem.component === 'events') {
                return (
                  <Events usePhoto={false}/>
                );
              }
              if (contentItem.component === 'newsLetters') {
                return (
                  <Newsletters usePhoto={false}/>
                );
              }
              
            }
            return null;
          })}
        </div>
      </div>
    );
  }

  if (loading) {
    return "loading";
  }
  return (
    <div className={styles.easyPage}>
      {easyPage.columns &&
        easyPage.columns.map((column) => {
          return siteColumn(column);
        })}
    </div>
  );
}

export default observer(EasyPage);
