import styles from "./bulletin.module.scss";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import { useMst } from "../../models/root";


function Bulletin() {
    const { t } = useTranslation('Newsletters');
  const params = useParams();
  const { newslettersStore } = useMst();
  const { newsletters } = newslettersStore;
  const bulletin = newsletters.get(params.id)
  return (
    <div className={styles.bulletin}>
      <object data={bulletin.pdf} className={styles.link}>
        Open
      </object>
    </div>
  );
}

export default Bulletin;
