import sanityClient from "@sanity/client";

export default sanityClient({
  projectId: "i8qa8uqx", // find this at manage.sanity.io or in your sanity.json
  dataset: "production", // this is from those question during 'sanity init'
  apiVersion: '2021-08-29',
  useCdn: true,
  withCredentials: false,
});

// Use this to get drafts in response
// export default sanityClient({
//   projectId: "i8qa8uqx", // find this at manage.sanity.io or in your sanity.json
//   dataset: "production", // this is from those question during 'sanity init'
//   apiVersion: '2021-08-29',
//   useCdn: false,
//   withCredentials: true,
// });
