import { observer } from "mobx-react-lite";
import styles from "./menubar.module.scss";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useMst } from "../../../models/root";
import { useEffect } from "react";

function MenuBar(props) {
  const navigate = useNavigate();
  const { language, setPageId, pageId, easyPagesStore } = useMst();
  const { fetchEasyPages, navigation} = easyPagesStore;
  const { t } = useTranslation("App");

  useEffect(() => {
    fetchEasyPages();
  }, [language, fetchEasyPages]);
  function goToNewPath(nav) {
    navigate(nav.path);
    setPageId(nav.key);
  }

  return (
    <div className={styles.menuBar}>
      <div className={styles.buttons}>
        {navigation.map((nav) => {
          const buttonStyle =
            nav.key === pageId
              ? `${styles.button} ${styles.open}`
              : styles.button;
          return (
            <button
              key={nav.key}
              className={buttonStyle}
              onClick={() => goToNewPath(nav)}
            >
              {t(nav.key) || nav.key}
            </button>
          );
        })}
      </div>
    </div>
  );
}

export default observer(MenuBar);
