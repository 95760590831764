/* eslint-disable no-useless-escape */
import { useParams } from "react-router-dom";
import { mobileAndTabletCheck } from "../../../utils/utils";
export default function Pdf() {
  const params = useParams();
  console.log(params);
  const fileId = params.fileId;
  const filename = `https://cdn.sanity.io/files/i8qa8uqx/production/${fileId}.pdf`;

  

  const isMObile = mobileAndTabletCheck();
  if (isMObile) {
    return (
      <iframe
        src={`https://drive.google.com/viewerng/viewer?embedded=true&url=${filename}`}
        width="100%"
        height="90%"
        type="application/pdf"
        title="Title"
      />
    );
  }
  return (
    <object
      type="application/pdf"
      data={filename}
      style={{ height: "100%", width: "100%" }}
    >
      {filename}
    </object>
  );
}
