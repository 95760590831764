import { useNavigate } from "react-router-dom";
import styles from "./photoHeader.module.scss";

export default function PhotoHeader({
  image,
  description,
  title,
  color,
  backgroundColor,
  fontSize = undefined,
  width = "100%",
  height = "unset",
  linkUrl,
  pagePath,
  showLabel = true,
}) {
  const navigate = useNavigate();
  function goToPath(link) {
    if (linkUrl ) {
      window.open(link, '_blank');
    } else if(pagePath) {
      navigate(link);
    }
  }
  const photoHeaderClass = linkUrl || pagePath ? `${styles.photoHeader} ${styles.link}` : styles.photoHeader;
  return (
    <div
      // style={{ backgroundColor: `${backgroundColor}66` }}
      className={photoHeaderClass}
      onClick={() => goToPath(linkUrl || pagePath)}
      role="button"
    >
      <div
        className={styles.photoFrame}
        // style={{ width: width, height: height }}
      >
        <img
          className={styles.image}
          src={image}
          alt={description}
          style={{
            width: `${width}`,
            height: `${height}`,
          }}
        />
      </div>
      {showLabel && <div
        className={styles.title}
        style={{
          //backgroundColor: `${backgroundColor}66`,
          // width: width,
          // height: height,
        }}
      >
        <div
          className={styles.titleText}
          style={{
            color: color,
            backgroundColor: `${backgroundColor}88`,
            fontSize: `calc(${fontSize} + 1.5vw)`,
          }}
        >
          {title}
        </div>
      </div>}
    </div>
  );
}
