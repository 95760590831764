/* eslint-disable react-hooks/exhaustive-deps */
import styles from "./newsletters.module.scss";
import { useNavigate } from "react-router-dom";
import { useMst } from "../../models/root";
import { useTranslation } from "react-i18next";
import PhotoHeader from "../../components/common/photoHeader/photoHeader";
import image from "./PA_22.png";
import { observer } from "mobx-react-lite";
import { useEffect } from "react";
import { mobileAndTabletCheck, getFileIdFromPdfUrl } from "../../utils/utils";

function Newsletters({ usePhoto = true }) {
  const { i18n, t } = useTranslation("Newsletters");
  const navigate = useNavigate();
  const { newslettersStore } = useMst();
  const { fetchNewsletters, getNewsletters, loading } = newslettersStore;
  useEffect(() => {
    fetchNewsletters();
  }, [i18n.language]);

  const newsletters = getNewsletters();
  function goToPath(path) {
    navigate(path);
  }
  if (loading) {
    return <div>Loading</div>;
  }
  const isMObile = mobileAndTabletCheck();
  return (
    <div className={styles.newsletters}>
      {newsletters.map((newsletter) => {
        let bulletinPath;
        if (isMObile) {
          bulletinPath = `/pdf/${getFileIdFromPdfUrl(newsletter.pdf)}`;
        } else {
          bulletinPath = `/bulletin/${newsletter._id}`;
        }
        return (
          <div
            role="button"
            title={t("openBulletin")}
            onClick={() => goToPath(bulletinPath)}
            key={newsletter._id}
            className={styles.newsletter}
          >
            <div className={styles.description}>{newsletter.description}</div>
            <div className={styles.date}>
              {t("date", { date: newsletter.date })}
            </div>
          </div>
        );
      })}
    </div>
  );
}

export default observer(Newsletters);
