import { values } from "mobx";
import { types, flow, getParent, getRoot } from "mobx-state-tree";
import sanityClient from "../client";

const Color = types.model({
  color: "#F3F3F7",
  backgroundColor: "#13294B",
});


export const EasyPageLink = types
  .model({
    _key: types.maybeNull(types.string),
    _type: types.maybeNull(types.string),
    name: types.maybeNull(types.string),
    color: types.maybeNull(Color),
    easyPageId: types.maybeNull(types.string),
    easyPageShortId: types.maybeNull(types.string),
  })
  .views((self) => {
    return {
      get url() {
        if (self.easyPageShortId) {
          return `/pages/${self.easyPageShortId}`;
        }
        return `/easyPage/${self.easyPageId}`;
      },
    };
  });


const EasyColumn = types.model({
  _key: types.maybeNull(types.string),
  name: types.maybeNull(types.string),
  gridColumn: types.maybeNull(types.string),
  gridRow: types.maybeNull(types.string),
  color: types.maybeNull(types.string),
  height: types.maybeNull(types.string),
  width: types.maybeNull(types.string),
  backgroundColor: types.maybeNull(types.string),
  topMargin: types.maybeNull(types.string),
  bottomMargin: types.maybeNull(types.string),
  leftMargin: types.maybeNull(types.string),
  rightMargin: types.maybeNull(types.string),
  content: types.maybeNull(
    types.frozen(),
  ),
});

const EasyPage = types.model({
  _id: types.identifier,
  shortId: types.maybeNull(types.string),
  addToMenu: false,
  menuOrder: types.maybeNull(types.number),
  name: types.maybeNull(types.string),
  columns: types.maybeNull(types.array(EasyColumn)),
});

const EasyPageId = types.model({
  shortId: types.identifier,
  easyPage: types.reference(EasyPage),
});

const EasyPages = types
  .model({
    easyPages: types.map(EasyPage),
    loading: false,
    state: "done",
    easyPagesIds: types.map(EasyPageId),
  })
  .views((self) => {
    return {
      get allEasyPages() {
        return values(self.easyPages);
      },
      getEasyPage(easyPageId) {
        return self.easyPages.get(easyPageId);
      },
      getEasyPageByShortId(easyPageShortId) {
        const easyPageId = self.easyPagesIds.get(easyPageShortId);
        if (easyPageId) {
          return easyPageId.easyPage;
        }
        return null;
      },
      get addToMenuPages() {
        return self.allEasyPages
          .filter((page) => page.addToMenu)
          .sort((prev, next) => {
            if (next.menuOrder < prev.menuOrder) {
              return 1;
            }
            return -1;
          });
      },
      get navigation() {
        let name = "DISCIPLINES";
        let devName;
        const disciplines =
          getRoot(self).customPagesStore.getCustomPageByShortId(
            "PAQDISCIPLINES"
          );
        if (disciplines) {
          name = disciplines.name;
        }
        const committes = self.getEasyPageByShortId('COMMITTEES');
        const committesName = committes?.name;
        const development = self.getEasyPageByShortId('DEVELOPMENT');
        if (development) {
          devName = development.name;
        } else {
          devName = 'LEARNING';
        }
        let eventsName;
        const events = self.getEasyPageByShortId('EVENTS');
        if (events) {
          eventsName = events.name;
        } else {
          eventsName = 'EVENTS';
        }
        let newsLettersName;
        const newsLetters = self.getEasyPageByShortId('NEWS');
        if (newsLetters) {
          newsLettersName = newsLetters.name;
        } else {
          newsLettersName = 'NEWS';
        }

        const navigationAfter = [
          { path: "/", key: "home" },
          { path: "/about", key: "about" },
          { path: "/disciplines", key: name },
          { path: "/members", key: "members" },
          { path: "/pages/COMMITTEES", key: committesName},
          { path: "/pages/DEVELOPMENT", key: devName},
          { path: "/pages/EVENTS", key: eventsName},
          { path: "/pages/NEWS", key: newsLettersName },        
        ];
        return navigationAfter;
        // const customNavigation = self.addToMenuPages.map((easyPage) => {
        //   return { path: `/easyPage/${easyPage._id}`, key: easyPage.name };
        // });
        // return customNavigation.concat(navigationAfter);
      },
    };
  })
  .actions((self) => {
    const fetchEasyPages = flow(function* () {
      // <- note the star, this is a generator function!
      self.state = "pending";
      self.loading = true;
      const language = getParent(self).language;
      try {
        // ... yield can be used in async/await style
        yield getRoot(self).customPagesStore.fetchCustomPages();
        const easyPagesArray = yield sanityClient.fetch(
          `*[_type == "easyPage"]
          {
            _id,
            shortId,
            addToMenu,
            menuOrder,
            "name": name.${language},
            
                  
                  "columns": columns[]
                    {
                      _key,
                      name,
                      gridColumn,
                      gridRow,
                      "color": color->color,
                      "backgroundColor": color->backgroundColor,
                      width,
                      height,
                      topMargin,
                      bottomMargin,
                      leftMargin,
                      rightMargin,
                      "content": columnContent[]
                      {
                         _type == 'easyPageLink' => @{_key,_type,"name":title.${language}, "color": color->{color, backgroundColor}, 
                                                           "easyPageId":easyPage->_id, "easyPageShortId": easyPage->shortId},
                         _type == 'userFile' => @{_key, _type, "name": name.${language}, "color": color->{color, backgroundColor},
                                                  "url": file.${language}.asset->url, embedd},  
                         _type == 'userUrl' => @{_key, _type, "name": label.${language}, "color": color->{color, backgroundColor},
                                                  "url": url.${language}, isYouTube},                       
                        _type == 'localeBlock' => @{_key, _type, marginTop, marginRight, marginBottom, marginLeft, "color": color->color,
                                                 "backgroundColor": color->backgroundColor, "block": ${language}[]},
                        _type == 'disciplineArea' => @{_key, _type, "widgetType": ^.widgetType[0],"spanRight": ^.spanRight, "spanDown": ^.spanDown, "_id": discipline->_id ,
                                                       "key": discipline->key, "color": discipline->color->{color, backgroundColor}, "imageUrl": discipline->image.asset->url,
                                                       "name": discipline->name.${language}, "customPageId": discipline->customPage->_id, "easyPageId": discipline->easyPage->_id, "userPageId": discipline->userPage->_id},
                        _type == 'localizedImage' => @{_key, _type, description, "url": photo.${language}.asset->url, 
                                                               width, "height": height, "linkTo": linkTo[0].url.${language}, "easyPageShortId": easyPage->shortId, setAsBackground,
                                                                },
                        _type == 'eventsList' => @{_key, _type, numberOfEvents, eventType},
                        _type == 'separator' => @{_key, _type, description, width, height, "color": color->color, "backgroundColor": color->backgroundColor},
                        _type == 'reference' => @->{
                          _type == 'carousel' => @{_id, "_key": _id, _type, carouselId}
                        },
                        _type == 'component' => @{_key, _type, "component": component->identifier}
                       
                      }
                    }
                     
                 
          }[0...100]`
        );

        self.easyPages.clear();
        self.easyPagesIds.clear();
        easyPagesArray.forEach((element) => {
          const easyPage = self.easyPages.put(element);
          if (element.shortId) {
            self.easyPagesIds.put({
              shortId: element.shortId,
              easyPage: easyPage._id,
            });
          }
        });
        self.loading = false;
        self.state = "done";
      } catch (error) {
        // ... including try/catch error handling
        console.error("Failed to fetch site pages", error);
        self.loading = false;
        self.state = "error";
      }
    });

    return {
      fetchEasyPages,
    };
  });

export default EasyPages;
