import styles from "./home.module.scss";
import Disciplines from "../components/disciplines/disciplines";
import Welcome from "../components/welcome/welcome";
import Partners from "../components/partners/partners";

export default function Home() {
  return (
    <div className={styles.home}>
      <div className={styles.content}>
        <div className={styles.welcome}>
          <Welcome />
        </div>
        <div className={styles.disciplines}>
          <Disciplines />
        </div>
       
      </div>
      <div className={styles.partner}>
          <Partners />
        </div>
    </div>
  );
}
