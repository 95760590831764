import { values } from "mobx";
import { types, flow, getParent } from "mobx-state-tree";
import sanityClient from "../client";

const Color = types.model({
  color: "#F3F3F7",
  backgroundColor: "#13294B",
});

const Span = types.model({
  _key: types.maybeNull(types.string),
  text: types.maybeNull(types.string),
  _type: types.maybeNull(types.string),
});

const Discipline = types.model({
  key: types.maybeNull(types.string),
  _id: types.identifier,
  color: types.maybeNull(Color),
  imageUrl: types.maybeNull(types.string),
  name: types.string,
  _type: types.maybeNull(types.string),
  userPageId: types.maybeNull(types.string),
});

const UserFile = types.model({
  _key: types.maybeNull(types.string),
  _type: types.maybeNull(types.string),
  name: types.maybeNull(types.string),
  color: types.maybeNull(Color),
  url: types.maybeNull(types.string),
  embedd: types.maybeNull(types.boolean),
});

const PageLink = types.model({
  _key: types.maybeNull(types.string),
  _type: types.maybeNull(types.string),
  name: types.maybeNull(types.string),
  color: types.maybeNull(Color),
  userPageId: types.maybeNull(types.string),
})
.views((self) => {
  return {
    get url(){
      return `/userPage/${self.userPageId}`
    }
  };
});

const SectionColumn = types.model({
  _key: types.maybeNull(types.string),
  description: "",
  spanDown: 1,
  spanRight: 1,
  content: types.maybeNull(
    types.array(
      types.union(
        {
          dispatcher: (snapshot) => {
            if (snapshot._type === "pageLink") {
              return PageLink;
            }
            if (snapshot._type === "userFile") {
              return UserFile;
            }
            if (snapshot._type === "block") {
              return Span;
            }
            if (snapshot._type === "userUrl") {
              return UserFile;
            }
            if (snapshot._type === "discipline") {
              return Discipline;
            }
          },
        },
        Span,
        Discipline,
        UserFile,
        PageLink
      )
    )
  ),
});

const SectionRow = types.model({
  _key: types.maybeNull(types.string),
  name: types.maybeNull(types.string),
  sectionColumns: types.array(SectionColumn),
});

const Section = types.model({
  _key: types.maybeNull(types.string),
  name: types.maybeNull(types.string),
  sectionRows: types.maybeNull(types.array(SectionRow)),
});

const UserPage = types.model({
  _id: types.identifier,
  addToMenu: false,
  name: types.maybeNull(types.string),
  color: types.maybeNull(Color),
  photoUrl: types.maybeNull(types.string),
  sections: types.maybeNull(types.array(Section)),
});

const UserPages = types
  .model({
    userPages: types.map(UserPage),
    loading: true,
    state: "done",
  })
  .views((self) => {
    return {
      get allUserPages() {
        return values(self.userPages);
      },
      getUserPage(userPageId) {
        return self.userPages.get(userPageId);
      },
      get addToMenuPages() {
        return self.allUserPages.filter(page => page.addToMenu);
      }
    };
  })
  .actions((self) => {
    const fetchUserPages = flow(function* () {
      // <- note the star, this is a generator function!
      self.state = "pending";
      self.loading = true;
      const language = getParent(self).language;
      try {
        // ... yield can be used in async/await style
        const userPagesArray = yield sanityClient.fetch(
          `*[_type == "userPage"]
          {
            _id,
            addToMenu,
            "photoUrl": image.asset->url,
            "name": name.${language},
            "color": color->{color,backgroundColor},
            "sections" : sections[]
            { _key, name, 
                "sectionRows": sectionRows[]
                {_key, name,
                  "sectionColumns": sectionColumns[]
                  {_key, description, spanDown, spanRight,
                    "content": content[]
                    {
                      _type == 'pageLink' => @{_key,_type,"name":title.${language}, "color": color->{color, backgroundColor}, "userPageId":userPage->_id},
                      _type == 'userFile' => @{_key, _type, "name": name.${language}, "color": color->{color, backgroundColor},
                                                  "url": file.${language}.asset->url, embedd},  
                      _type == 'userUrl' => @{_key, _type, "name": label.${language}, "color": color->{color, backgroundColor},
                                                  "url": url.${language}},  
                      
                      _type == 'block' => @{_key, _type, text},
                      _type == 'reference' => @->
                      {
                                _type == 'discipline' => @{key, _id, _type, "imageUrl": image.asset->url, "name": name.${language}, 
                                                          "color": color->{color, backgroundColor}, "userPageId": userPage->_id}
                      },
                    
                    }
                  }
                }
            }
            
          }[0...10]`
        );
        self.userPages.clear();
        userPagesArray.forEach((element) => {
          self.userPages.put(element);
        });
        self.loading = false;
        self.state = "done";
      } catch (error) {
        // ... including try/catch error handling
        console.error("Failed to fetch events", error);
        self.loading = false;
        self.state = "error";
      }
    });

    return {
      fetchUserPages,
    };
  });

export default UserPages;
