/* eslint-disable react-hooks/exhaustive-deps */
import styles from "./sitePage.module.scss";
import { rootStore, useMst } from "../../models/root";
import { observer } from "mobx-react-lite";
import { useEffect } from "react";
import { useParams } from "react-router-dom";
import Discipline from "../discipline/discipline";
import UserFile from "../userFile/userFile";
import { PortableText } from "@portabletext/react";
import EventsList from "../eventsList/eventsList";

function SitePage() {
  const params = useParams();
  const { sitePagesStore } = useMst();
  const { fetchSitePages, getSitePage, getSitePageByShortId, loading } =
    sitePagesStore;
  const { language } = rootStore;

  useEffect(() => {
    fetchSitePages();
  }, []);
  useEffect(() => {
    fetchSitePages();
  }, [language]);

  let sitePage;
  if (params.sitePageShortId) {
    sitePage = getSitePageByShortId(params.sitePageShortId);
  } else {
    sitePage = getSitePage(params.sitePageId);
  }

  if (!sitePage) {
    sitePage = getSitePage("f728ba12-5028-476b-8a70-812e22a7321c");
  }

  if (!sitePage || loading) {
    return <div>Loading</div>;
  }

  function image({ url, alt, width, height }) {
    return (
      <div className={`${styles.columnContent} ${styles.localizedImage}`}>
        <img
          className={styles.image}
          src={url}
          alt={alt}
          style={{
            "--width": `${width}`,
            "--height": `${height}`,
          }}
        />
      </div>
    );
  }

  function siteRow(row) {
    const backgroundColor = row.backgroundColor || "unset";
    const color = row.color || "unset";
    const topMargin = row.topMargin || "0";
    const bottomMargin = row.bottomMargin || "0";
    const leftMargin = row.leftMargin || "0";
    const rightMargin = row.rightMargin || "0";
    return (
      <div className={styles.row}
      style={{
        "--backgroundColor": backgroundColor,
        "--marginTop": topMargin,
        "--marginBottom": bottomMargin,
        "--marginLeft": leftMargin,
        "--marginRight": rightMargin,
        "--color": color,
        "--width": row.width,
        "--height": row.height,
      }}
      >
        <div
          className={styles.rowMarginTop}
          style={{
            "--backgroundColor": backgroundColor,
            "--topMargin": topMargin,
            "--bottomMargin": bottomMargin,
            "--leftMargin": leftMargin,
            "--rightMargin": rightMargin,
            "--color": color,
          }}
        />
        <div
          key={row._key}
          className={styles.rowBody}
          style={{
            "--backgroundColor": backgroundColor,
            "--topMargin": topMargin,
            "--bottomMargin": bottomMargin,
            "--leftMargin": leftMargin,
            "--rightMargin": rightMargin,
            "--color": color,
          }}
        >
          {row.columns.map((column) => {
            const backgroundColor = row.backgroundColor || "unset";
            const color = row.color || "unset";
            const topMargin = row.topMargin || "0";
            const bottomMargin = row.bottomMargin || "0";
            const leftMargin = row.leftMargin || "0";
            const rightMargin = row.rightMargin || "0";
            return (
              <div
                key={column._key}
                className={styles.column}
                style={{
                  "--width": column.width,
                  "--backgroundColor": backgroundColor,
                  "--topMargin": topMargin,
                  "--bottomMargin": bottomMargin,
                  "--leftMargin": leftMargin,
                  "--rightMargin": rightMargin,
                  "--color": color,
                }}
              >
                {column.content.map((contentItem) => {
                  const contentType = contentItem._type;

                  if (contentType === "localizedImage") {
                    return image(contentItem);
                  }
                  if (contentType === "localeBlock") {
                    return (
                      <div className={`${styles.columnContent}`}>
                        <PortableText
                          key={contentItem._key}
                          value={contentItem.block}
                        />
                      </div>
                    );
                  }

                  if (
                    contentType === "userFile" ||
                    contentType === "userUrl" ||
                    contentType === "customPageLink"
                  ) {
                    return (
                      <div className={`${styles.columnContent}`}>
                        <UserFile contentItem={contentItem} />
                      </div>
                    );
                  }

                  if (contentType === "siteRow") {
                    return siteRow(contentItem);
                  }

                  if (contentType === "eventsList") {
                    return (
                      <div className={`${styles.columnContent}`}>
                        <EventsList
                          color={contentItem.color}
                          backgroundColor={contentItem.backgroundColor}
                        />
                      </div>
                    );
                  }
                  if (contentType === "disciplineArea") {
                    return (
                      <div className={`${styles.columnContent}`}>
                        <Discipline
                          name={contentItem.name}
                          imageUrl={contentItem.imageUrl}
                          color={contentItem.color}
                          userPageId={contentItem.userPageId}
                          customPageId={contentItem.customPageId}
                          widgetType={contentItem.widgetType}
                        />
                      </div>
                    );
                  }
                  return null;
                })}
              </div>
            );
          })}
        </div>
        <div
          className={styles.rowMarginBottom}
          style={{
            "--backgroundColor": backgroundColor,
            "--topMargin": topMargin,
            "--bottomMargin": bottomMargin,
            "--leftMargin": leftMargin,
            "--rightMargin": rightMargin,
            "--color": color,
          }}
        />
      </div>
    );
  }
  //     <div
  //       key={row._key}
  //       className={styles.row}
  //       style={{
  //         "--backgroundColor": backgroundColor,
  //         "--topMargin": topMargin,
  //         "--bottomMargin": bottomMargin,
  //         "--leftMargin": leftMargin,
  //         "--rightMargin": rightMargin,
  //         "--color": color,
  //       }}
  //     >
  // {row.columns.map((column) => {
  //     const backgroundColor = row.backgroundColor || "unset";
  //     const color = row.color || "unset";
  //     const topMargin = row.topMargin || "0";
  //     const bottomMargin = row.bottomMargin || "0";
  //     const leftMargin = row.leftMargin || "0";
  //     const rightMargin = row.rightMargin || "0";
  //     return
  //     (
  //       <div
  //           key={contentItem._key}
  //           className={styles.column}
  //           style={{
  //             "--backgroundColor": backgroundColor,
  //             "--topMargin": topMargin,
  //             "--bottomMargin": bottomMargin,
  //             "--leftMargin": leftMargin,
  //             "--rightMargin": rightMargin,
  //             "--color": color,
  //           }}
  //         >
  //         {
  //           column.content.map((contentItem) =>
  //           {
  //             const contentType = contentItem._type;

  //             if (contentType === "localizedImage" ) {
  //               return (image(contentItem));
  //             }
  //             if (contentType === "localeBlock") {
  //               return (
  //                 <div className={`${styles.columnContent}`}>
  //                  <PortableText
  //                    key={contentItem._key}
  //                    value={contentItem.block}
  //                  />
  //                 </div>
  //               );
  //             }

  //            if (contentType === "userFile" ||
  //               contentType === "userUrl" ||
  //               contentType === "customPageLink") {
  //               return (
  //                 <div className={`${styles.columnContent}`}>
  //                   <UserFile contentItem={contentItem} />
  //                 </div>
  //               );
  //            }

  //             if (contentType === "siteRow") {
  //               return (siteRow(contentItem));
  //             }

  //           })
  //         }
  //       </div>
  //           );}
  //     })

  //     </div>
  //   );

  // }

  return (
    <div className={styles.sitePage}>
      {sitePage.rows &&
        sitePage.rows.map((row) => {
          if (row.columns) {
            return siteRow(row);
          }
          return [];
        })}
    </div>
  );
}

export default observer(SitePage);
