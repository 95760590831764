import styles from "./event.module.scss";
import { useTranslation } from "react-i18next";
import Swimmer from "../../art/swimmer.jpg";
import { useNavigate } from "react-router-dom";
import { useParams } from "react-router-dom";
import { rootStore, useMst } from "../../models/root";
import { observer } from "mobx-react-lite";
import { useEffect } from "react";
import PhotoHeader from "../../components/common/photoHeader/photoHeader";
import UserFile from "../userFile/userFile";

function Event() {
  const params = useParams();
  const navigate = useNavigate();
  const { eventsStore } = useMst();
  const { events, fetchEvents } = eventsStore;
  const { language } = rootStore;
  const event = events.get(params.id);
  const { t } = useTranslation("Event");
  let data = event;
  const photo = data.photoUrl ? data.photoUrl : Swimmer;

  useEffect(() => {
    fetchEvents();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [language]);

  return (
    <div className={styles.event}>
      <PhotoHeader
        className={styles.photo}
        image={photo}
        description={data.description}
        title={data.description}
      />
      <div className={styles.eventDetails}>{t("eventDetails")}</div>
      <div className={styles.dateAndLocation}>{data.dateandlocation}</div>
      {data.hosts &&
        data.hosts.map((host) => {
          return (
            <div key={host.name} className={styles.host}>
              <div className={styles.hostName}>
                {t("hostNameLabel", { hostName: host.name })}
              </div>
            </div>
          );
        })}
      {data.venue && (
        <>
          <div className={styles.venueLabel}>{t("venueLabel")}</div>
          <div className={styles.venue}>{data.venue}</div>
        </>
      )}
      {data.website && (
        <a
          href={data.website}
          className={styles.hostWebsite}
          target="_blank"
          rel="noreferrer"
        >
          {t("websiteLabel")}
        </a>
      )}
      {data.streamSite && (
        <a
          href={data.streamSite}
          className={styles.hostWebsite}
          target="_blank"
          rel="noreferrer"
        >
          {t("streamSiteLabel")}
        </a>
      )}
      {data.liveResults && (
        <a
          href={data.liveResults}
          className={styles.hostWebsite}
          target="_blank"
          rel="noreferrer"
        >
          {t("liveResultsLabel")}
        </a>
      )}
      {data.summons && (
        <a
          href={data.summons}
          className={styles.hostSummons}
          target="_blank"
          rel="noreferrer"
        >
          {t("summonsLabel")}
        </a>
      )}
      {data.supporters && (
        <a
          href={data.supporters}
          className={styles.hostSupporters}
          target="_blank"
          rel="noreferrer"
        >
          {t("supportersLabel")}
        </a>
      )}
      {data.bioSecurity && (
        <a
          href={data.bioSecurity}
          className={styles.bioSecurity}
          target="_blank"
          rel="noreferrer"
        >
          {t("bioSecurityLabel")}
        </a>
      )}
      {data.eventSchedule &&
        data.eventSchedule.file &&
        !data.eventSchedule.embedd && (
          <>
            <a
              href={data.eventSchedule.file}
              className={styles.schedule}
              target="_blank"
              rel="noreferrer"
            >
              {t("scheduleLabel")}
            </a>
          </>
        )}
      {data.eventSchedule &&
        data.eventSchedule.file &&
        data.eventSchedule.embedd && (
          <>
            <div className={styles.scheduleLabel}>{t("scheduleLabel")}</div>
            <embed
              src={data.eventSchedule.file}
              className={styles.embeddedSchedule}
            />
          </>
        )}
      {data.links && (
        <>
          {data.links &&
            data.links.map((link) => {
              return (
                <a
                  key={link._key}
                  style={{
                    color: link.color,
                    backgroundColor: link.backgroundColor,
                  }}
                  href={link.url}
                  className={styles.results}
                  target="_blank"
                  rel="noreferrer"
                >
                  {link.label}
                </a>
              );
            })}
        </>
      )}
      {data.pages && (
        <>
          {data.pages &&
            data.pages.map((page) => {
              return (
                <button
                  style={{
                    color: page.color,
                    backgroundColor: page.backgroundColor,
                  }}
                  key={page._key}
                  onClick={() => navigate(`/page/${params.id}/${page._key}`)}
                  className={styles.results}
                >
                  {page.title}
                </button>
              );
            })}
        </>
      )}

      {data.results && (
        <>
          {data.results &&
            data.results.map((result) => {
              return (
                <a
                  key={result._key}
                  href={result.pdf}
                  className={styles.results}
                  target="_blank"
                  rel="noreferrer"
                >
                  {result.description}
                </a>
              );
            })}
        </>
      )}
       {data.customPageLinks && (
        <>
          {data.customPageLinks &&
            data.customPageLinks.map((contentItem) => {
              return (
                <div
                  key={contentItem._key}
                  className={styles.columnContent}
                >
                  <UserFile contentItem={contentItem} />
                </div>
              );
            })}
        </>
      )}
    </div>
  );
}

export default observer(Event);
