import React from "react";
import ReactDOM from "react-dom";
import { Provider, rootStore } from "./models/root";
import "./index.css";
import "./i18n";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import About from "./components/about/about";
import Event from "./components/event/event";
import Members from "./components/members/members";
import Home from "./routes/home";
import Newsletters from "./components/newsletters/newsletters";
import Bulletin from "./components/bulletin/bulletin";
import Events from "./components/events/events";
import Calendar from "./components/calendar/calendar";
import Redirect from './components/redirect/redirect';
import Page from './components/page/page';
import UserPage from "./components/userPage/userPage";
import CustomPage from "./components/customPage/customPage";
import SitePage from "./components/sitePage/sitePage";
import EasyPage from "./components/easyPage/easyPage";
import Carousel from "./components/common/carousel/carousel";
import Pdf from "./components/common/pdf/pdf";

ReactDOM.render(
  <Provider value={rootStore}>
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<App />}>
          <Route index element={<EasyPage />} />
          <Route path="about" element={<CustomPage id= "PAQABOUT"/>} />
          <Route path="disciplines" element={<CustomPage id= "PAQDISCIPLINES"/>} />
          <Route path="members" element={<Members />} />
          <Route path="newsletters" element={<Newsletters />} />
          <Route path="events" element={<Events />} />
          <Route path="calendar" element={<Calendar />} />
          <Route path="home" element={<Home />} />
          <Route path="bulletin/:id" element={<Bulletin />} />
          <Route path="event/:id" element={<Event />} />
          <Route path="budapest_EN" element={<Redirect  loc="https://drive.google.com/drive/folders/1k9QUyJLqwNhjap7yRyJ9hCbgOdEu9eso?usp=sharing"/>}  />
          <Route path="budapest_ES" element={<Redirect  loc="https://drive.google.com/drive/folders/1t3q43ojfRg467Q0oZdglS-fX0pzFz2Iu?usp=sharing"/>}  />
          <Route path="*" element={<EasyPage />} />
          <Route path="page/:eventId/:pageId" element={<Page />} />
          <Route path="userPage/:userPageId" element={<UserPage />} />
          <Route path="customPage/:customPageId" element={<CustomPage />} />
          <Route path="site/:sitePageShortId" element={<SitePage />} />
          <Route path="pages/:easyPageShortId" element={<EasyPage />} />
          <Route path="page/:customPageShortId" element={<CustomPage />} />
          <Route path="pdf/:fileId" element={<Pdf />} />
          <Route path="carousel" element={<Carousel />} />
          <Route path="calendarEvents" element={<Redirect  loc="https://cdn.sanity.io/files/i8qa8uqx/production/b105b222c8c608acb71bb14827f0d719006b99b4.pdf"/>}  />
        </Route>
      </Routes>
    </BrowserRouter>
  </Provider>,
  document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
