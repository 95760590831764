import { useEffect } from "react";

function Redirect(props) {
    useEffect(() => {
        console.log(props.loc);
        window.location.replace(props.loc);
    }, [props.loc])
    return (<div>Redirecting...</div>)
}

export default Redirect;