/* eslint-disable react-hooks/exhaustive-deps */
import styles from "./chats.module.scss";
// import { useNavigate } from "react-router-dom";
import { rootStore, useMst } from "../../models/root";
import { observer } from "mobx-react-lite";
import { useEffect } from "react";
// import { useParams } from "react-router-dom";

import YouTube from "react-youtube";

function Chats({chatType = 'underwater', audienceType = 'forBoth'}) {
  // const params = useParams();
  const { chatsStore } = useMst();
  // const navigate = useNavigate();
  const { fetchChats, getChats, loading } = chatsStore;
  const { language } = rootStore;

  useEffect(() => {
    fetchChats();
  }, []);
  useEffect(() => {
    fetchChats();
  }, [language]);

  if (loading) {
    return "loading";
  }
  return (
    <div className={styles.chats}>
      {getChats(chatType, audienceType).map((chat) => {
        let videoId;
        if (chat.youtubeLink) {
          videoId = chat.youtubeLink.split("/").pop();
        }
        const opts = { height: "100%", width: "100%" };
        return (
          <div className={styles.video}>
            <div className={styles.description}>{chat.description}</div>
           {videoId && <YouTube className={styles.link} videoId={videoId} opts={opts} />}
          </div>
        );
      })}
    </div>
  );
}

export default observer(Chats);
