/* eslint-disable react-hooks/exhaustive-deps */
import styles from "./userPage.module.scss";
import { rootStore, useMst } from "../../models/root";
import PhotoHeader from "../../components/common/photoHeader/photoHeader";
import { observer } from "mobx-react-lite";
import { useEffect } from "react";
import { useParams } from "react-router-dom";
import Discipline from "../discipline/discipline";
import UserFile from "../userFile/userFile";

function UserPage() {
  const params = useParams();
  const { userPagesStore } = useMst();
  const { fetchUserPages, getUserPage, loading } = userPagesStore;
  const { language } = rootStore;

  useEffect(() => {
    fetchUserPages();
  }, []);
  useEffect(() => {
    fetchUserPages();
  }, [language]);

  if (loading) {
    return <div>Loading</div>;
  }
  const userPage = getUserPage(params.userPageId);
  if (!userPage) {
    return <div>Loading</div>;
  }
  const sections = userPage.sections;
  return (
    <div className={styles.userPage}>
      <div className={styles.topMargin} />
      <PhotoHeader
        className={styles.photo}
        image={userPage.photoUrl}
        description={userPage.name}
        title={userPage.name}
        color={userPage.color.color}
        backgroundColor={userPage.color.backgroundColor}
      />
      <div className={styles.content}>
        {sections.map((section) => {
          const sectionRows = section.sectionRows;
          return (
            <div key={section._key} className={styles.section}>
              {sectionRows.map((sectionRow) => {
                const sectionColumns = sectionRow.sectionColumns;
                return (
                  <div key={sectionRow._key} className={styles.sectionRow}>
                    {sectionColumns.map((sectionColumn) => {
                      const content = sectionColumn.content;
                      return (
                        <div
                          key={sectionColumn._key}
                          className={styles.sectionColumn}
                        >
                          {content.map((contentItem) => {
                            const contentType = contentItem._type;
                            if (contentType === "block") {
                              if (contentItem.text === null) {
                                return null;
                              }
                              return (
                                <div
                                  key={contentItem._key}
                                  className={styles.columnContent}
                                >
                                  {contentItem.text}
                                </div>
                              );
                            }
                            if (contentType === "discipline") {
                              return (
                                <div
                                  key={contentItem._id}
                                  className={styles.columnContent}
                                >
                                  <Discipline
                                    name={contentItem.name}
                                    imageUrl={contentItem.imageUrl}
                                    color={contentItem.color}
                                    userPageId={contentItem.userPageId}
                                  />
                                </div>
                              );
                            }
                            if (
                              contentType === "userFile" ||
                              contentType === "userUrl" ||
                              contentType === "pageLink"
                            ) {
                              return (
                                <div
                                  key={contentItem._key}
                                  className={styles.columnContent}
                                >
                                  <UserFile contentItem={contentItem} />
                                </div>
                              );
                            }
                            return null;
                          })}
                        </div>
                      );
                    })}
                  </div>
                );
              })}
            </div>
          );
        })}
      </div>
    </div>
  );
}

export default observer(UserPage);
