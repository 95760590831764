import { useTranslation } from "react-i18next";
import styles from "./disciplines.module.scss";
import { ReactComponent as ArtisticSwimmingLogo } from "./artisticSwimming.svg";
import { ReactComponent as DivingLogo } from "./diving.svg";
import { ReactComponent as WaterPoloLogo } from "./waterPolo.svg";
import { ReactComponent as MastersLogo } from "./masters.svg";
import { ReactComponent as HighDivingLogo } from "./highDiving.svg";
import { ReactComponent as SwimmingLogo } from "./swimming.svg";
import { ReactComponent as OpenWaterLogo } from "./openWater.svg";

export default function Disciplines() {
  const { t } = useTranslation("Disciplines");
  return (
    <div className={styles.disciplines}>
      <div className={styles.divingLabel}>{t("diving")}</div>
      <div className={styles.mastersLabel}>{t("masters")}</div>
      <div className={styles.swimmingLabel}>{t("swimming")}</div>
      <div className={styles.artisticLabel}>{t("artistic")}</div>
      <div className={styles.waterLabel}>{t("water")}</div>
      <div className={styles.openLabel}>{t("open")}</div>
      <div className={styles.highLabel}>{t("high")}</div>

      <div className={styles.artisticLabel2}>{t("artisticSwimming")}</div>
      <div className={styles.waterLabel2}>{t("waterPolo")}</div>
      <div className={styles.openLabel2}>{t("openWater")}</div>
      <div className={styles.highLabel2}>{t("highDiving")}</div>
      <DivingLogo className={styles.diving} />
      <MastersLogo className={styles.masters} />
      <SwimmingLogo className={styles.swimming} />
      <ArtisticSwimmingLogo className={styles.artistic} />
      <WaterPoloLogo className={styles.water} />
      <OpenWaterLogo className={styles.open} />
      <HighDivingLogo className={styles.high} />
    </div>
  );
}
