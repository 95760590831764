import React from "react";
import { Outlet } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { Analytics } from "@vercel/analytics/react";
import "@fontsource/roboto/300.css";
import "@fontsource/roboto/400.css";
import "@fontsource/roboto/500.css";
import "@fontsource/roboto/700.css";
import styles from "./App.module.scss";
import TopMenu from "./components/topMenu/topMenu";

const PageNameContext = React.createContext("");
function App() {
  const { t } = useTranslation("App");

  return (
    <>
      <PageNameContext.Provider value={t("home")}>
        <div className={styles.main}>
          <div className={styles.app}>
            <div className={styles.topPanel}>
              <TopMenu />
            </div>
            <div className={styles.page}>
              <Outlet />
            </div>
            <div className={styles.bottomMargin} />
          </div>
        </div>
      </PageNameContext.Provider>
      <Analytics />
    </>
  );
}

export default App;
