import styles from "./partners.module.scss";
import { ReactComponent as PanamSportsLogo } from "./PanamSportsLogo.svg";
import FinaLogo from "./finalogo2015.jpg";
import { ReactComponent as FluidraLogo } from "./fluidra.svg";
import CONSANATLogo from "./consanatlogo.jpg";
import CCCANLogo from "./CCCANlogo.jpg";
import USASLogo from "./USANF.png";
import ACALogo from "./AquaticCanadaAquatiqueslogo.jpg";
import { ReactComponent as HastyAwardsLogo } from "./hasty.svg";

export default function Partners() {
  function gotoFluidra() {
    window.open("https://www.fluidra.com", '_blank');
  }
  function gotoHasty() {
    window.open("https://www.hastyawards.com", '_blank');
  }
  function gotoPanamSportsLogo() {
    window.open("https://www.panamsports.org", '_blank');
  }
  function gotoFina() {
    window.open("https://www.fina.org", '_blank');
  }
  return (
    <div className={styles.partners}>
      <div className={styles.firstLevel}>
        <img onClick={() => gotoFina()} src={FinaLogo} alt="FINA" className={styles.fina} />
        <PanamSportsLogo onClick={() => gotoPanamSportsLogo()} className={styles.panamSports} />
        <FluidraLogo onClick={() => gotoFluidra()} alt="Fluidra" className={styles.fluidra} />
      </div>
      <div className={styles.secondLevel}>
        <img src={CONSANATLogo} alt="CONSANT" className={styles.CONSANAT} />
        <img src={CCCANLogo} alt="CCCAN" className={styles.CCCAN} />
        <img src={USASLogo} alt="USAS" className={styles.USAS} />
        <img src={ACALogo} alt="ACA" className={styles.ACA} />
      </div>
      <div className={styles.thirdLevel}>
        <HastyAwardsLogo onClick={() => gotoHasty()} className={styles.hastyAwards} />
      </div>
    </div>
  );
}
