import styles from "./page.module.scss";
import { rootStore, useMst } from "../../models/root";
import { useParams } from "react-router-dom";
import { observer } from "mobx-react-lite";
import { useEffect } from "react";
import PhotoHeader from "../common/photoHeader/photoHeader";

function Page() {
  const params = useParams();

  const { eventsStore } = useMst();
  const { getPage, fetchEvents } = eventsStore;
  const { language } = rootStore;

  const page = getPage(params.eventId, params.pageId);
  const pageGroups = page.linksGroup;

  useEffect(() => {
    fetchEvents();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [language]);
  return (
    <div className={styles.page}>
      <PhotoHeader
        className={styles.photo}
        image={page.photo}
        description={page.title}
        title={page.title}
      />
      <div className={styles.groups}>
        {pageGroups.map((group) => {
          const groupLinks = group.links;
          return (
            <div key={group._key} className={styles.group}>
              <div className={styles.title}>{group.title}</div>
              <div className={styles.links}>
                {groupLinks.map((link) => {
                  return (
                    <a
                      style={{
                        color: link.color,
                        backgroundColor: link.backgroundColor,
                      }}
                      key={link._key}
                      href={link.href}
                      className={styles.link}
                      target="_blank"
                      rel="noreferrer"
                    >
                      {link.title}
                    </a>
                  );
                })}
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
}

export default observer(Page);
