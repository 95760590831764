import styles from "./userFile.module.scss";
import { useNavigate } from "react-router-dom";
import { getFileIdFromPdfUrl } from "../../utils/utils";

function UserFile({ contentItem }) {
  const navigate = useNavigate();
  const color = contentItem.color && contentItem.color.color ? contentItem.color.color : "red";
  const backgroundColor = contentItem.color && contentItem.color.backgroundColor ? contentItem.color.backgroundColor : "cyan";
  function open() {
    if (contentItem._type === 'easyPageLink') {
        navigate(`/pages/${contentItem.easyPageShortId}`);
    } else if (contentItem._type === "pageLink"  || contentItem._type === "customPageLink") {
      navigate(contentItem.url);
    } else if (contentItem.url.toLowerCase().includes('.pdf')) {
      const filenameId = getFileIdFromPdfUrl(contentItem.url);
      navigate(`/pdf/${filenameId}`);
    }
    else {
      window.open(contentItem.url, "_blank");
    }
  }
  return (
    <div className={styles.userFile}>
      <div
        className={styles.name}
        style={{
          color: color,
          backgroundColor: backgroundColor,
        }}
        onClick={() => open()}
      >
        <div className={styles.text}>{contentItem.name}</div>
      </div>
    </div>
  );
}

export default UserFile;
