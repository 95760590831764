import { values } from "mobx";
import { types, flow } from "mobx-state-tree";
import sanityClient from "../client";
import i18n from "../i18n";

const Newsletter = types.model({
  _id: types.identifier,
  date: "",
  description: types.maybeNull(types.string),
  pdf: "",
});

const Newsletters = types
  .model({
    newsletters: types.map(Newsletter),
    loading: false,
    state: "done",
  })
  .views((self) => {
    return {
      getNewsletters() {
        return values(self.newsletters);
      },
    };
  })
  .actions((self) => {
    const fetchNewsletters = flow(function* () {
      // <- note the star, this is a generator function!
      self.state = "pending";
      self.loading = true;
      try {
        // ... yield can be used in async/await style
        const newslettersArray = yield sanityClient.fetch(
          `*[_type == "newsletter"] | order(date desc)
          {
            _id,
            date,
            "description": description.${i18n.language},
            "pdf": pdf.${i18n.language}.asset->url,
          }[0...10]`
        );
        self.newsletters.clear();
        newslettersArray.forEach((element) => {
          self.newsletters.put(element);
        });
        self.loading = false;
        self.state = "done";
      } catch (error) {
        // ... including try/catch error handling
        console.error("Failed to fetch projects", error);
        self.loading = false;
        self.state = "error";
      }
    });

    // function afterCreate() {
    //     fetchNewsletters();
    // }

    return { fetchNewsletters };
  });

export default Newsletters;
