import { onSnapshot, types } from "mobx-state-tree";
import { createContext, useContext } from "react";
import CustomPages from "./customPages";
import Events from "./events";
import Newsletters from "./newsletters";
import UserPages from "./userPages";
import CarouselStore from "./carouselStore";
import SitePages from './sitePages';
import EasyPages from './easyPages';
import ChatsStore from "./chatsStore";

const RootModel = types.model({
  pageId: 'home',
  language: 'en',
  newslettersStore: types.optional(Newsletters,{}),
  eventsStore: types.optional(Events,{}),
  userPagesStore: types.optional(UserPages,{}),
  customPagesStore: types.optional(CustomPages,{}),
  carouselStore: types.optional(CarouselStore, {}),
  sitePagesStore: types.optional(SitePages, {}),
  easyPagesStore: types.optional(EasyPages, {}),
  chatsStore: types.optional(ChatsStore, {},)
})
.actions(self => ({
  setPageId(pageId) {
    self.pageId = pageId;
  },
  setLanguage(language) {
    self.language = language;
  }
}));

let initialState = RootModel.create({});


  const data = localStorage.getItem("rootState");
  if (data) {
    const json = JSON.parse(data);
    if (RootModel.is(json)) {
      initialState = RootModel.create(json);
    }
  }


export const rootStore = initialState;

onSnapshot(rootStore, (snapshot) => {
  //console.log("Snapshot: ", snapshot);
  localStorage.setItem("rootState", JSON.stringify(snapshot));
});

// export type RootInstance = Instance<typeof RootModel>;
const RootStoreContext = createContext(null);

export const Provider = RootStoreContext.Provider;
export function useMst() {
  const store = useContext(RootStoreContext);
  if (store === null) {
    throw new Error("Store cannot be null, please add a context provider");
  }
  return store;
}