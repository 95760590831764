import styles from "./discipline.module.scss";
import { useNavigate } from "react-router-dom";
import PhotoHeader from "../common/photoHeader/photoHeader";

const Discipline = ({
  imageUrl,
  name,
  color,
  userPageId,
  widgetType,
  customPageId,
}) => {
  const navigate = useNavigate();
  let pagePath = undefined;
  if (userPageId) {
    pagePath = `/userPage/${userPageId}`;
  } else if (customPageId) {
    pagePath = `/customPage/${customPageId}`;
  }

  function goToPage() {
    if (userPageId) {
      navigate(`/userPage/${userPageId}`);
    } else if (customPageId) {
      navigate(`/customPage/${customPageId}`);
    }
  }
  if (widgetType === "logo") {
    return (
      <div 
      className={`${styles.discipline} 
      ${styles.logo}`}
      onClick={() => goToPage()}
      >
        <img src={imageUrl} alt={name} className={styles.photo} />
        <div className={styles.label}>
          <div className={styles.text}> {name}</div>
        </div>
      </div>
    );
  }
  return (
    <div className={styles.discipline}>
      <div className={styles.image}>
        <img className={styles.img} src={imageUrl} alt={name} />
      </div>
      <div
        className={styles.name}
        style={{ color: color.color, backgroundColor: color.backgroundColor }}
        onClick={() => goToPage()}
      >
        {name}
      </div>
    </div>
  );
};

export default Discipline;
