import styles from "./welcome.module.scss";
import Pool from './pool.png';
import { useTranslation } from 'react-i18next';
function Welcome() {
    const { t } = useTranslation('Welcome');
  return (
    <div className={styles.welcome}>
      <div className={styles.panam}>
        <img className={styles.image} src={Pool} alt="Welcome"/>
      </div>
      <div className={styles.title}>{t('welcome')}</div>
    </div>
  );
}
export default Welcome;
