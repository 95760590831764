import { useState } from "react";
import styles from "./accordion.module.scss";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
function Accordion(props) {
  const [open, setOpen] = useState(false);
  return (
    <div className={styles.accordion}>
      <div className={styles.headerWrapper}>
        <div className={styles.leftMargin} />
        <div className={styles.toggler}>
          {!open && <KeyboardArrowDownIcon onClick={() => setOpen(!open)} />}
          {open && <KeyboardArrowUpIcon onClick={() => setOpen(!open)} />}
        </div>
        <div className={styles.header}>{props.header}</div>
        <div className={styles.rightMargin} />
      </div>
      {open && <div className={styles.children}>{props.children}</div>}
    </div>
  );
}
export default Accordion;
