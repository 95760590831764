/* eslint-disable react-hooks/exhaustive-deps */
import styles from "./calendar.module.scss";
import { useNavigate } from "react-router-dom";
import { rootStore, useMst } from "../../models/root";
import { useTranslation } from "react-i18next";
import image from "../../art/swimmer.jpg";
import { observer } from "mobx-react-lite";
import { useEffect } from "react";
import Carousel from "../common/carousel/carousel";
import Disciplines from "../../components/disciplines/disciplines";

function Calendar() {
  const { t } = useTranslation("Events");
  const navigate = useNavigate();
  const { eventsStore } = useMst();
  const { fetchEvents, getEvents, loading } = eventsStore;
  const { language } = rootStore;

  useEffect(() => {
    fetchEvents();
  }, []);

  useEffect(() => {
    fetchEvents();
  }, [language]);

  const events = getEvents();
  function goToPath(path) {
    navigate(path);
  }
  if (loading) {
    return <div>Loading</div>;
  }
  return (
    <div className={styles.calendar}>
      <div className={styles.photo}>
        <Carousel />
      </div>
      {/* <div className={styles.disciplines}>
      </div> */}
      <div className={styles.list}>
        {events.map((event) => {
          let eventPath;
          if (
            event.useFirstCustomPageLinkAsContent &&
            event.customPageLinks &&
            event.customPageLinks.length > 0
          ) {
            eventPath = event.customPageLinks[0].url;
          } else {
            eventPath = `/event/${event._id}`;
          }
          return (
            <div
              key={event._id}
              role="button"
              title={t("openEvent")}
              onClick={() => goToPath(eventPath)}
              className={styles.event}
            >
              <div className={styles.description}>{event.description}</div>
              <div className={styles.date}>
                {/* {t("date", { date: event.startDate })} */}
                {event.dateandlocation}
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
}

export default observer(Calendar);
