import { values } from "mobx";
import { types, flow } from "mobx-state-tree";
import sanityClient from "../client";
import i18n from "../i18n";
import { CustomPageLink, EasyPageLink} from './customPages';


const Result = types.model({
  _key: '',
  date: '',
  description:'',
  pdf: types.maybeNull(types.string),
});
const Link = types.model({
  _key: '',
  label: types.maybeNull(types.string),
  name: types.maybeNull(types.string),
  color: '#13294B',
  backgroundColor: '#F3F3E7',
  url: types.maybeNull(types.string),
  fileUrl: types.maybeNull(types.string),
  order: 10,
  embedd: types.maybeNull(types.boolean),
})
.views((self) => {
  return {
    get isFile() {
      return self.fileUrl !== null;
    },
    get isLink() {
      return self.fileUrl !== null;
    },
    get title() {
      return self.label || self.name;
    },
    get href() {
      return self.url || self.fileUrl;
    }
    
  };
});
const Host = types.model({
  name: '',
  website: '',
});
const Discipline = types.model({
  key: '',
  name: '',
});
const EventSchedule = types.model({
  file: types.maybeNull(types.string),
  embedd: types.maybeNull(types.boolean),
});
const LinksGroup = types.model({
  _key: '',
  title: types.maybeNull(types.string),
  order: types.maybeNull(types.number),
  links: types.maybeNull(types.array(Link)),
});
const Page = types.model({
  _key: types.identifier,
  title: types.maybeNull(types.string),
  order: types.maybeNull(types.number),
  photo: types.maybeNull(types.string),
  color: '#13294B',
  backgroundColor: '#F3F3E7',
  linksGroup: types.maybeNull(types.array(LinksGroup)),
});


const Event = types.model({
  _id: types.identifier,
  description: "",
  photoUrl: types.maybeNull(types.string),
  disciplines: types.array(Discipline),
  startDate: "",
  endDate: "",
  color: types.maybeNull(types.string),
  backgroundColor: types.maybeNull(types.string),
  dateandlocation: types.maybeNull(types.string),
  hosts: types.maybeNull(types.array(Host)),
  venue: types.maybeNull(types.string),
  schedule: types.maybeNull(types.string),
  summons: types.maybeNull(types.string),
  supporters: types.maybeNull(types.string),
  results: types.maybeNull(types.array(Result)),
  links: types.maybeNull(types.array(Link)),
  website: types.maybeNull(types.string),
  bioSecurity: types.maybeNull(types.string),
  streamSite: types.maybeNull(types.string),
  liveResults: types.maybeNull(types.string),
  eventSchedule: types.maybeNull(EventSchedule),
  pages: types.maybeNull(types.array(Page)),
  customPageLinks: types.maybeNull(
    types.array(
      types.union(
        {
          dispatcher: (snapshot) => {
            if (snapshot && snapshot._type === "easyPageLink") {
              return EasyPageLink;
            }
            if (snapshot && snapshot._type === "customPageLink") {
              return CustomPageLink;
            }
            return CustomPageLink;
          },
        },
        CustomPageLink,
        EasyPageLink,
      )
    )
  ),
  useFirstCustomPageLinkAsContent: types.maybeNull(types.boolean),
}).views((self) => {
  return {
    get startTime() {
      return new Date(self.startDate).getTime();
    },
    get endTime() {
      return new Date(self.endDate).getTime();
    },
  };
});

const Events = types
  .model({
    events: types.map(Event),
    loading: false,
    state: "done",
  })
  .views((self) => {
    return {
      getEvents() {
        return values(self.events);
      },
      getPage(eventId, pageId) {
        return self.events.get(eventId).pages.find(element => element._key === pageId);
      },
      get upcomingEvents() {
        let currentDate = new Date();
        currentDate.setDate(currentDate.getDate() - 1);
        const currentTime = currentDate.getTime();
        return self.getEvents().filter(item => item.endTime >= currentTime).sort((a , b) => a.startTime - b.startTime);
      },
      get pastEvents() {
        const currentTime = new Date().getTime();
        return self.getEvents().filter(item => item.endTime < currentTime).sort((a , b) => b.endTime - a.endTime);
      },

    };
  })
  .actions((self) => {
    const fetchEvents = flow(function* () {
      // <- note the star, this is a generator function!
      self.state = "pending";
      self.loading = true;
      try {
        // ... yield can be used in async/await style
        const eventsArray = yield sanityClient.fetch(
          `*[_type == "event"] | order(startDate desc)
          {
            _id,
            "photoUrl": photo.asset->url,
            startDate,
            endDate,
            "dateandlocation": coalesce(dateandlocation.${i18n.language},dateandlocation.en),
            "description": coalesce(description.${i18n.language},description.en) ,
            "color": color->color,
            "backgroundColor": color->backgroundColor,
            venue,
            "summons": summons.${i18n.language}.asset->url,
            "bioSecurity": bioSecurity.${i18n.language}.asset->url,
            "schedule": schedule.${i18n.language}.asset->url,
            "eventSchedule": {"file": eventSchedule.file.${i18n.language}.asset->url, "embedd": eventSchedule.embedd},
            "supporters": supporters.${i18n.language}.asset->url,
            "results": results[] |  order(date desc) {_key, date, "description":description.${i18n.language}, "pdf":pdf.${i18n.language}.asset->url},
            "links": links[] |  order(order asc) {_key, order, "color": color->color, "backgroundColor":color->backgroundColor, "label":label.${i18n.language}, "url":url.${i18n.language}},
            "hosts": hosts[]->{name, website},
            "disciplines": disciplines[]->{key, "name":name.${i18n.language}},
            website,
            streamSite,
            liveResults,
            "customPageLinks": customPageLinks[]
            {
              _type == 'easyPageLink' => @{_key,_type,"name":title.${i18n.language}, "color": color->{color, backgroundColor}, 
                                                           "easyPageId":easyPage->_id, "easyPageShortId": easyPage->shortId},
              _type == 'customPageLink' => @{_key,_type,"name":title.${i18n.language}, "color": color->{color, backgroundColor}, "customPageId":customPage->_id, "customPageShortId": customPage->shortId},
            },
            useFirstCustomPageLinkAsContent,
            "pages": pages[] |  order(order asc) { _key, order, "color": color->color, "backgroundColor":color->backgroundColor, "title":title.${i18n.language}, "photo": photo.asset->url,
             "linksGroup": linksGroup[] |  order(order asc) {_key, "title":title.${i18n.language}, order, 
              "links": links[] {_key, "name":name.${i18n.language}, "fileUrl":file.${i18n.language}.asset->url, order, "color": color->color, "backgroundColor":color->backgroundColor, embedd, "label":label.${i18n.language}, "url":url.${i18n.language} } } }
          }[0...100]`
        );
        self.events.clear();
        eventsArray.forEach((element) => {
          self.events.put(element);
        });
        self.loading = false;
        self.state = "done";
      } catch (error) {
        // ... including try/catch error handling
        console.error("Failed to fetch events", error);
        self.loading = false;
        self.state = "error";
      }
    });

    return { fetchEvents };
  });

export default Events;
