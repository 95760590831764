import { useEffect, useState } from "react";
import { Navigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useMst } from "../../../models/root";
import styles from "./overflow.module.scss";
import { ReactComponent as Hamburger90 } from "../../../art/hamburger90.svg";

function Overflow(props) {
  const { setPageId, language, easyPagesStore } = useMst();
  const { fetchEasyPages , navigation} = easyPagesStore;
  const { t } = useTranslation("App");
  const [path, setPath] = useState(undefined);
  const { close } = props;

  useEffect(() => {
    fetchEasyPages();
  }, [language, fetchEasyPages]);
  useEffect(() => {
    if (path) {
      setPageId(path.replace("/", ""));
      close();
    }
  }, [close, path, setPageId]);

  return (
    <div className={styles.overflow}>
      <div className={styles.screen}>
        <div className={styles.rightPanel}>
          <div className={styles.topMargin} />
          <div className={styles.rightMargin} />
          <div className={styles.bottomMargin} />
          <div
            className={styles.hamburger90}
            role="button"
            onClick={() => close()}
          >
            <Hamburger90 className={styles.icon} />
          </div>
          <div className={styles.afterIconMargin} />
          <div className={styles.buttons}>
            {navigation.map((path) => {
              return (
                <div className={styles.option}>
                  <button
                    key={path.path}
                    className={styles.button}
                    onClick={() => setPath(path.path)}
                  >
                    {t(path.key)}
                  </button>
                  <div className={styles.optionRightMargin}/>
                </div>
              );
            })}
          </div>
          <div className={styles.rightMragin} />
          {path && <Navigate to={path} replace={false} />}
        </div>
      </div>
    </div>
  );
}

export default Overflow;
