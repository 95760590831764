import { types, flow } from "mobx-state-tree";
import i18n from "../i18n";
import sanityClient from "../client";
import { values } from "mobx";

const Discipline = types.model({
  name: '',
  imageUrl: '',
});

const Chat = types.model({
    _id: types.identifier,
    description: types.maybeNull(types.string),
    chatType: "",
    audienceType: types.maybeNull(types.string),
    date: types.maybeNull(types.string),
    order: types.maybeNull(types.number),
    youtubeLink: types.maybeNull(types.string),
    disciplines: types.maybeNull(types.array(Discipline)),
});

const ChatsStore = types
  .model({
    loading: false,
    chatsMap: types.map(Chat),
    state: 'done',
  })
  .views (self => {
    return {
        get chats() {
            return values(self.chatsMap);
        },
        getChats(chatType, audienceType) {
          return self.chats.filter(chat => chat.chatType === chatType && (chat.audienceType === audienceType || chat.audienceType === "forBoth"));
        }

    }
  })
  .actions((self) => {
    const fetchChats = flow(function* () {
      // <- note the star, this is a generator function!
      self.state = "pending";
      self.loading = true;
      try {
        // ... yield can be used in async/await style
        const chatsArray = yield sanityClient.fetch(
          `*[_type == "chat"] | order(date desc)
          {
            _id,
            date,
            order,
            chatType,
            audienceType,
            "description": coalesce(description.${i18n.language},description.en) ,
            "youtubeLink": youtubeLink.url.${i18n.language},
            "disciplines": disciplines[]->{"name": name.${i18n.language}, "imageUrl": image.asset->url},
          }[0...100]`
        );
        self.chatsMap.clear();
        chatsArray.forEach((element) => {
          self.chatsMap.put(element);
        });
        self.loading = false;
        self.state = "done";
      } catch (error) {
        // ... including try/catch error handling
        console.error("Failed to fetch chats", error);
        self.loading = false;
        self.state = "error";
      }
    });

    return { fetchChats};
  });

export default ChatsStore;
