/* eslint-disable react-hooks/exhaustive-deps */
import styles from "./customPage.module.scss";
import { rootStore, useMst } from "../../models/root";
import PhotoHeader from "../common/photoHeader/photoHeader";
import { observer } from "mobx-react-lite";
import { useEffect } from "react";
import { useParams } from "react-router-dom";
import Discipline from "../discipline/discipline";
import UserFile from "../userFile/userFile";
import { PortableText } from "@portabletext/react";
import EventsList from "../eventsList/eventsList";

function CustomPage({id}) {
  const params = useParams();
  const { customPagesStore } = useMst();
  const { fetchCustomPages, getCustomPage, getCustomPageByShortId, loading } =
    customPagesStore;
  const { language } = rootStore;

  useEffect(() => {
    fetchCustomPages();
  }, []);
  useEffect(() => {
    fetchCustomPages();
  }, [language]);

  let customPage;
  if (params.customPageShortId) {
    customPage = getCustomPageByShortId(params.customPageShortId);
  } else {
    customPage = getCustomPage(params.customPageId);
  }

  if (!customPage) {
    if (id) {
      customPage = getCustomPageByShortId(id);
    } else {
      customPage = getCustomPageByShortId('home');
    }
    
  }

  if (!customPage || loading) {
    return <div>Loading</div>;
  }
  const areas = customPage.areas;
  return (
    <div className={styles.customPage}>
      <div className={styles.content}>
        {areas.map((area) => {
          const areaRows = area.areaRows;
          return (
            <div key={area._key} className={styles.area}>
              {areaRows.map((areaRow) => {
                const areaColumns = areaRow.areaColumns;
                const backgroundColor = areaRow.backgroundColor || "unset";
                const color = areaRow.color || "unset";
                const topMargin = areaRow.topMargin || "1rem";
                const bottomMargin = areaRow.bottomMargin || "1rem";
                const leftMargin = areaRow.leftMargin || "1rem";
                const rightMargin = areaRow.rightMargin || "1rem";
                return (
                  <div
                    key={areaRow._key}
                    className={styles.areaRow}
                    style={{
                      "--backgroundColor": backgroundColor,
                      "--topMargin": topMargin,
                      "--bottomMargin": bottomMargin,
                      "--leftMargin": leftMargin,
                      "--rightMargin": rightMargin,
                      "--color": color,
                    }}
                  >
                    <div className={styles.rowBody}>
                      {areaColumns.map((contentItem) => {
                        const contentType = contentItem._type;
                        if (contentType === "block") {
                          if (contentItem.text === null) {
                            return null;
                          }
                          return (
                            <div
                              key={contentItem._key}
                              className={styles.columnContent}
                            >
                              {contentItem.text}
                            </div>
                          );
                        }
                        if (contentType === "disciplineArea") {
                          return (
                            <div
                              key={contentItem._key}
                              className={styles.columnContent}
                            >
                              <Discipline
                                name={contentItem.name}
                                imageUrl={contentItem.imageUrl}
                                color={contentItem.color}
                                userPageId={contentItem.userPageId}
                                customPageId={contentItem.customPageId}
                                widgetType={contentItem.widgetType}
                              />
                            </div>
                          );
                        }
                        if (
                          contentType === "userFile" ||
                          contentType === "userUrl" ||
                          contentType === "customPageLink" ||
                          contentType === "easyPageLink"
                        ) {
                          return (
                            <div
                              key={contentItem._key}
                              className={styles.columnContent}
                            >
                              <UserFile contentItem={contentItem} />
                            </div>
                          );
                        }
                        if (contentType === "localeImage") {
                          const color = contentItem.color
                            ? contentItem.color
                            : {
                                color: "transparent",
                                backgroundColor: "transparent",
                              };
                          return (
                            <div
                              key={contentItem._key}
                              className={`${styles.columnContent} ${styles.image}`}
                            >
                              <PhotoHeader
                                key={contentItem._key}
                                image={contentItem.url}
                                description={contentItem.name}
                                title={contentItem.name}
                                color={color.color}
                                backgroundColor={color.backgroundColor}
                                showLabel={contentItem.showLabel}
                                width={contentItem.width}
                                height={contentItem.height}
                                linkUrl={contentItem.linkTo}
                              />
                            </div>
                          );
                        }
                        if (contentType === "localeBlock") {
                          return (
                            <div
                              key={contentItem._key}
                              className={`${styles.columnContent} ${styles.portableText}`}
                              style={{
                                "--marginTop": contentItem.marginTop || "0",
                                "--marginRight": contentItem.marginRight || "0",
                                "--marginBottom":
                                  contentItem.marginBottom || "0",
                                "--marginLeft": contentItem.marginLeft || "0",
                                "--color": contentItem.color || "black",
                                "--backgroundColor":
                                  contentItem.backgroundColor || "white",
                              }}
                            >
                              {/* <div className={styles.localeBlockTopMargin} />
                              <div className={styles.localeBlockRightMargin} />
                              <div className={styles.localeBlockBottomMargin} />
                              <div className={styles.localeBlockLeftMargin} /> */}
                              <div className={styles.block}>
                                <PortableText
                                  key={contentItem._key}
                                  value={contentItem.block}
                                />
                              </div>
                            </div>
                          );
                        }
                        if (contentType === "eventsList") {
                          return (
                            <div
                              key={contentItem._key}
                              className={styles.columnContent}
                            >
                              <EventsList
                                color={contentItem.color}
                                backgroundColor={contentItem.backgroundColor}
                              />
                            </div>
                          );
                        }

                        return null;
                      })}
                    </div>
                    <div className={styles.topMargin} />
                    <div className={styles.bottomMargin} />
                    <div className={styles.leftMargin} />
                    <div className={styles.rightMargin} />
                  </div>
                );
              })}
            </div>
          );
        })}
      </div>
    </div>
  );
}

export default observer(CustomPage);
