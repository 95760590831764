import styles from "./carousel.module.scss";
import { useMst } from "../../../models/root";
import { useEffect, useState } from "react";
import Swimmer from "../../../art/artistic.jpg";
import { observer } from "mobx-react-lite";

function Carousel({ carouselId = "home" }) {
  const { carouselStore, eventsStore, easyPagesStore } = useMst();
  const { fetchCarousels, getCarousel , loading} = carouselStore;
  const { loading: eventsLoading } = eventsStore;
  const { loading: easyPagesLoading } = easyPagesStore;
  const [animate, setAnimate] = useState(true);

  useEffect(() => {
    fetchCarousels();
  }, [fetchCarousels]);

  const homeCarousel = getCarousel(carouselId);
  const { images } = homeCarousel || { images: [{url: Swimmer}] };
  const frameStyle = animate ? styles.frame : `${styles.frame} ${styles.noanimation}`;
  if (eventsLoading || easyPagesLoading || loading) {
    return (
      <div className={styles.carousel} 
        style={{"--background": `url(${Swimmer})`}}
      >
        <div className={frameStyle} 
          onClick={() => setAnimate(!animate)}
        >
          
              <div key={'Swimmer'} className={styles.image}>
                <img
                  src={Swimmer}
                  // style={{ height: homeCarousel.height }}
                  alt="Welcome"
                  className={styles.img}
                />
              </div>
            
        </div>
      </div>
    );
  }
  return (
    <div className={styles.carousel} 
      style={{"--background": `url(${Swimmer})`}}
    >
      <div className={frameStyle} 
        onClick={() => setAnimate(!animate)}
      >
        {images.map((image) => {
          return (
            <div key={image._key} className={styles.image}>
              <img
                src={image.url}
                // style={{ height: homeCarousel.height }}
                alt="Welcome"
                className={styles.img}
              />
            </div>
          );
        })}
      </div>
    </div>
  );
}

export default observer(Carousel);
