import styles from "./social.module.scss";
import { ReactComponent as FacebookLogo } from "../../art/facebook.svg";
import { ReactComponent as InstagramLogo } from "../../art/instagram.svg";
import { useState } from "react";

function Social() {
  const instaAddress = "https://instagram.com/panamaquatics?igshid=YmMyMTA2M2Y=";
  const facebookAddress = 'https://www.facebook.com/PanAmAquatics/';
  
  function openInstagram() {
    window.open(instaAddress, '_blank');
  }
  function openFacebook() {
    window.open(facebookAddress, '_blank');
  }
   
  return (
    <div className={styles.social}>
      <div className={styles.facebook} role="button" onClick={() => openFacebook()}>
        <FacebookLogo className={styles.logo} />
      </div>
      <div className={styles.instagram} onClick={() => openInstagram()}>
        <InstagramLogo className={styles.logo} />
      </div>
    </div>
  );
}

export default Social;
