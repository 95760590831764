import HttpApi from "i18next-http-backend";
import { initReactI18next } from "react-i18next";
import LanguageDetector from "i18next-browser-languagedetector";
import i18n from "i18next";
import Pseudo from "i18next-pseudo";

const loadPath = "/locales/{{lng}}/{{ns}}.json";

const i18nOptions = {
  backend: { loadPath },

  // If you create a new translation file (e.g. locales/en/my-translation.json),
  // you’ll need to add its name — without the `.json` — to this array.
  //
  // To use translations that aren’t in the default (translation.json)
  // translations, you need to add the namespace before the translation key:
  //
  //     t('my-translation:key')
  //
  // See https://www.i18next.com/principles/namespaces.html for details.

  ns: ["App", "Home", "About", "Events", "Event",
   "Members", "Disciplines", "Welcome", 'Results', 'Newsletters'],
  // Without a namespace prefix, all translations load from translation.json
  defaultNS: "translation",
  fallbackLng: "en",
  supportedLngs: ["en", "es", "vi"],
  initImmediate: false,
  interpolation: {
    escapeValue: false, // not needed for React
  },
  detection: {
    order: ["querystring", "navigator", "cookie", "localStorage", "htmlTag"],
  },
  lowerCaseLng: true,
  react: {
    useSuspense: false,
  },
  postProcess: ["pseudo"],
};

i18n
  .use(new Pseudo({ wrapped: true, languageToPseudo: "vi" }))
  .use(HttpApi)
  .use(initReactI18next)
  .use(LanguageDetector)
  .init(i18nOptions);

export default i18n;
