import { useEffect, useState } from "react";
import { observer } from "mobx-react-lite";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import styles from "./topMenu.module.scss";
import { ReactComponent as Logo } from "./logo.svg";
import { ReactComponent as Hamburger } from "../../art/hamburger.svg";
import { ReactComponent as Hamburger90 } from "../../art/hamburger90.svg";
import Overflow from "../common/overflow/overflow";
import MenuBar from "../common/menubar/menubar";
import { useMst } from "../../models/root";
import Social from "../social/social";
function TopMenu() {
  const { language, setLanguage } = useMst();
  const { i18n } = useTranslation("App");
  const navigate = useNavigate();

  useEffect(() => {
    i18n.changeLanguage(language);
  }, [i18n, language]);

  const [overflowIsOpen, setOverflowIsOpen] = useState(false);
  const engStyle = language === "en" ? `${styles.eng} ${styles.selected}` : styles.eng;
  const espStyle = language === "es" ? `${styles.esp} ${styles.selected}` : styles.esp;
  return (
    <div className={styles.topMenu}>
      <div className={styles.logo} role="button" onClick={() => navigate('/')}>
        <Logo className={styles.icon} />
      </div>
      <div className={styles.menuBar}>
        <MenuBar />
      </div>

      <div className={styles.language}>
        <div className={styles.social}>
          <Social />
        </div>
        <div className={styles.socialsep}></div>
        <div
          className={engStyle}
          role="button"
          onClick={() => setLanguage("en")}
        >
          ENG
        </div>
        <div className={styles.sep}>|</div>
        <div
          className={espStyle}
          role="button"
          onClick={() => setLanguage("es")}
        >
          ESP
        </div>
      </div>

      <div
        className={styles.hamburger}
        role="button"
        onClick={() => setOverflowIsOpen(!overflowIsOpen)}
      >
        {!overflowIsOpen && <Hamburger className={styles.hamburgerIcon} />}
        {overflowIsOpen && <Hamburger90 className={styles.hamburgerIcon} />}
      </div>
      {overflowIsOpen && (
        <div className={styles.overflow}>
          <Overflow close={() => setOverflowIsOpen(false)} />
        </div>
      )}
    </div>
  );
}

export default observer(TopMenu);
