/* eslint-disable react-hooks/exhaustive-deps */
import Countdown from "react-countdown";
import styles from "./eventsList.module.scss";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import AccessAlarmIcon from "@mui/icons-material/AccessAlarm";
import { CalendarMonth } from "@mui/icons-material";
import AlarmOnIcon from "@mui/icons-material/AlarmOn";
import { useNavigate } from "react-router-dom";
import { rootStore, useMst } from "../../models/root";
import { useTranslation } from "react-i18next";
import PhotoHeader from "../../components/common/photoHeader/photoHeader";
import { observer } from "mobx-react-lite";
import { useEffect } from "react";
import { t } from "i18next";

const timer = ({
  days,
  hours,
  minutes,
  seconds,
  completed,
  dt = t("Events:days"),
  ht = t("Events:hours"),
  mt = t("Events:minutes"),
  st = t("Events:seconds"),
  happening = t("Events:happening"),
}) => {
  if (completed) {
    return (
      <div className={styles.happening}>
        <div className={styles.alarm}>
          <AlarmOnIcon fontSize="large" className={styles.wicon} />
        </div>
        <div className={styles.title}>{t(happening)}</div>
      </div>
    );
  }
  return (
    <div className={styles.time}>
      <div className={styles.alarm}>
        <AccessAlarmIcon className={styles.wicon} />
      </div>
      <div className={styles.days}>
        <div className={styles.title}>{dt}</div>
        <div className={styles.value}>{days}</div>
      </div>
      <div className={styles.hours}>
        <div className={styles.title}>{ht}</div>
        <div className={styles.value}>{hours}</div>
      </div>
      <div className={styles.minutes}>
        <div className={styles.title}>{mt}</div>
        <div className={styles.value}>{minutes}</div>
      </div>
      <div className={styles.seconds}>
        <div className={styles.title}>{st}</div>
        <div className={styles.value}>{seconds}</div>
      </div>
    </div>
  );
};
function EventsList({
  eventType = "upcoming",
  numberOfEvents = 1,
  backgroundColor = "transparent",
  color = "black",
}) {
  const { t } = useTranslation("Events");
  const navigate = useNavigate();
  const { eventsStore } = useMst();
  const { fetchEvents, loading, upcomingEvents, pastEvents } = eventsStore;
  const { language } = rootStore;

  useEffect(() => {
    fetchEvents();
  }, []);

  useEffect(() => {
    fetchEvents();
  }, [language]);

  function goToPath(evt, path, isInternal) {
    evt.stopPropagation();
    if (isInternal) {
      navigate(path);
    } else {
      window.open(path, "_blank");
    }
  }
  if (loading) {
    return <div>Loading</div>;
  }
  return (
    <div className={styles.eventsList}>
      <div className={styles.upcomingEventTopMargin} />
      <div className={styles.upcomingEventBottomMargin} />
      <div className={styles.upcomingEventRightMargin} />
      <div className={styles.upcomingEventLeftMargin} />
      {eventType === "upcoming" && (
        <div className={styles.upcoming}>
          {upcomingEvents.slice(0, numberOfEvents).map((event) => {
            let eventPath;
            let eventTitle = "";
            let urlTypeInternal = true;
            if (
              event.useFirstCustomPageLinkAsContent &&
              event.customPageLinks &&
              event.customPageLinks.length > 0
            ) {
              const customPageLink = event.customPageLinks[0];
              if (customPageLink.customPageShortId === "2023PAQDQC") {
                eventPath = `https://guanajuato2023.panamaquaticsevents.com/`;
                urlTypeInternal = false;
              } else {
                eventPath = event.customPageLinks[0].url;
              }

              eventTitle = event.customPageLinks[0].name;
            } else {
              eventPath = `/event/${event._id}`;
            }
            return (
              <div
                key={event._id}
                role="button"
                title={t("openEvent")}
                onClick={(evt) => goToPath(evt, eventPath, urlTypeInternal)}
                className={styles.event}
                style={{"--backgroundImage": `url("${event.photoUrl}")`, "--color": event.color, "--background": `linear-gradient(to right, transparent, ${event.backgroundColor}), url('${event.photoUrl}')`}}
              >
                <div
                  className={styles.right}
                  style={{
                    "--backgroundColor": backgroundColor,
                    "--color": event.color,
                  }}
                >
                  <div className={styles.upcomingEvent}>
                    {t("upcomingEvent")}
                  </div>
                  <div className={styles.description}>{event.description}</div>
                  <div className={styles.date}>
                    {/* {t("date", { date: event.startDate })} */}
                    {event.dateandlocation}
                  </div>
                  <div className={styles.navigate}>
                    <div className={styles.descritpion}>{eventTitle}</div>

                    <NavigateNextIcon className={styles.icon} />
                  </div>
                  {/* <div className={styles.countDown}>
                    <Countdown
                      date={new Date(event.startDate)}
                      renderer={timer}
                    />
                  </div> */}
                </div>
              </div>
            );
          })}
        </div>
      )}
      {eventType === "previous" && (
        <div className={styles.past}>
          <div className={styles.title}>{t("pastEvents")}</div>
          {pastEvents.map((event) => {
            let eventPath;
            if (
              event.useFirstCustomPageLinkAsContent &&
              event.customPageLinks &&
              event.customPageLinks.length > 0
            ) {
              eventPath = event.customPageLinks[0].url;
            } else {
              eventPath = `/event/${event._id}`;
            }
            return (
              <div
                key={event._id}
                role="button"
                title={t("openEvent")}
                onClick={(evt) => goToPath(evt, eventPath)}
                className={styles.event}
              >
                <div className={styles.description}>{event.description}</div>
                <div className={styles.date}>
                  {/* {t("date", { date: event.startDate })} */}
                  {event.dateandlocation}
                </div>
              </div>
            );
          })}
        </div>
      )}
      <div
        role="button"
        className={styles.calendar}
        onClick={(evt) => goToPath(evt, "/events", true)}
      >
        <div className={styles.calendarLabel}>
          <div className={styles.calendarIcon}>
            <CalendarMonth fontSize="large" />
          </div>
          <div className={styles.title}>{t("eventsCalendar")}</div>
        </div>
        <div className={styles.icon}>
          <NavigateNextIcon fontSize="large" />
        </div>
      </div>
    </div>
  );
}

export default observer(EventsList);
