import styles from "./about.module.scss";
import { useTranslation } from "react-i18next";
import Swimmer from "../../art/artistic.jpg";
function About() {
  const { t } = useTranslation("About");
  return (
    <div className={styles.about}>
      <div className={styles.header}>
        <div className={styles.swimmer}>
          <img className={styles.image} src={Swimmer} alt="Swimmer" />
        </div>
        <div className={styles.title}>{t("whoIs")}</div>
      </div>

      <div className={styles.whoIsDescription}>
        <div className={styles.whoIsDescription}>{t("whoIsDescription")}</div>
      </div>

      <div className={styles.inASentenceLabel}>{t("inASentenceLabel")}</div>

      <div className={styles.inASentence}>{t("inASentence")}</div>

      <div className={styles.missionVisionValuesLabel}>
        {t("missionVisionValuesLabel")}
      </div>
      <div className={styles.missionLabel}>{t("missionLabel")}</div>
      <div className={styles.mission}>{t("mission")}</div>
      <div className={styles.visionLabel}>{t("visionLabel")}</div>
      <div className={styles.vision}>{t("vision")}</div>
      <div className={styles.valuesLabel}>{t("valuesLabel")}</div>
      <div className={styles.values}>
        <div className={styles.valuesInclusive}>{t("values.inclusive")}</div>
        <div className={styles.valuesCollaborative}>
          {t("values.collaborative")}
        </div>
        <div className={styles.valuesGroundbreaking}>
          {t("values.groundbreaking")}
        </div>
      </div>
    </div>
  );
}
export default About;
