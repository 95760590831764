import { values } from "mobx";
import { types, flow, getRoot } from "mobx-state-tree";
import sanityClient from "../client";
import i18n from "../i18n";

const Image = types.model({
  _key: '', //types.identifier,
  url: types.string,
});

const Carousel = types
  .model({
    _id: "",
    carouselId: types.identifier,
    imagesList: types.array(Image),
    width: types.maybeNull(types.string),
    height: types.maybeNull(types.string),
  })
  .views((self) => {
    return {
      get images() {
        return self.imagesList;
      },
    };
  });
const CarouselStore = types
  .model({
    _id: "",
    carousels: types.map(Carousel),
    state: "pending",
    loading: true,
  })
  .views((self) => {
    return {
      getCarousel(carouselId) {
        return self.carousels.get(carouselId);
      },
    };
  })
  .actions((self) => {
    const fetchCarousels = flow(function* () {
      // <- note the star, this is a generator function!
      self.state = "pending";
      self.loading = true;
      const language = getRoot(self).language;
      try {
        const carouselsArray = yield sanityClient.fetch(
          `*[_type == "carousel"]
          {
            _id,
            carouselId,
            "imagesList": images[]{_key, "url": image.asset->url},  
            width,
            height,
          }[0...10]`
        );
        
        self.carousels.clear();
        carouselsArray.forEach((element) => {
          self.carousels.put(element);
        });
        self.loading = false;
        self.state = "done";
      } catch (error) {
        // ... including try/catch error handling
        console.error("Failed to fetch carousels", error);
        self.loading = false;
        self.state = "error";
      }
    });

    return {
      fetchCarousels,
    };
  });

export default CarouselStore;
