import { values } from "mobx";
import { types, flow, getParent } from "mobx-state-tree";
import sanityClient from "../client";

const Color = types.model({
  color: "#F3F3F7",
  backgroundColor: "#13294B",
});

const Block = types.model({
  _key: types.maybeNull(types.string),
  _type: types.maybeNull(types.string),
  marginTop: types.maybeNull(types.string),
  marginRight: types.maybeNull(types.string),
  marginBottom: types.maybeNull(types.string),
  marginLeft: types.maybeNull(types.string),
  color: types.maybeNull(types.string),
  backgroundColor: types.maybeNull(types.string),
  block: types.maybeNull(types.frozen()),
});

const Discipline = types.model({
  _key: types.maybeNull(types.string),
  key: types.maybeNull(types.string),
  _id: types.identifier,
  color: types.maybeNull(Color),
  imageUrl: types.maybeNull(types.string),
  name: types.string,
  _type: types.maybeNull(types.string),
  userPageId: types.maybeNull(types.string),
  sitePageId: types.maybeNull(types.string),
  widgetType: types.maybeNull(types.string),
});

const UserFile = types.model({
  _key: types.maybeNull(types.string),
  _type: types.maybeNull(types.string),
  name: types.maybeNull(types.string),
  color: types.maybeNull(Color),
  url: types.maybeNull(types.string),
  embedd: types.maybeNull(types.boolean),
});

export const SitePageLink = types
  .model({
    _key: types.maybeNull(types.string),
    _type: types.maybeNull(types.string),
    name: types.maybeNull(types.string),
    color: types.maybeNull(Color),
    sitePageId: types.maybeNull(types.string),
    sitePageShortId: types.maybeNull(types.string),
  })
  .views((self) => {
    return {
      get url() {
        if (self.sitePageShortId) {
          return `/page/${self.sitePageShortId}`;
        }
        return `/sitePage/${self.sitePageId}`;
      },
    };
  });

const Image = types.model({
  _key: types.maybeNull(types.string),
  _type: types.maybeNull(types.string),
  description: types.maybeNull(types.string),
  color: types.maybeNull(Color),
  url: types.maybeNull(types.string),
  embedd: types.maybeNull(types.boolean),
  showLabel: true,
  width: types.maybeNull(types.optional(types.string, "5rem")),
  height: types.maybeNull(types.string),
  linkTo: types.maybeNull(types.string),
});

const SiteEventsList = types.model({
  _key: types.maybeNull(types.string),
  _type: types.maybeNull(types.string),
  numberOfEvents: 1,
  eventType: "upcoming",
});

const SiteRow = types.model({
  _key: types.maybeNull(types.string),
  _type: types.maybeNull(types.string),
  name: types.maybeNull(types.string),
  color: types.maybeNull(types.string),
  width: types.maybeNull(types.string),
  height: types.maybeNull(types.string),
  backgroundColor: types.maybeNull(types.string),
  topMargin: types.maybeNull(types.string),
  bottomMargin: types.maybeNull(types.string),
  leftMargin: types.maybeNull(types.string),
  rightMargin: types.maybeNull(types.string),
  columns: types.maybeNull(types.array(types.late(() => SiteColumn))),
});

const SiteColumn = types.model({
  _key: types.maybeNull(types.string),
  name: types.maybeNull(types.string),
  color: types.maybeNull(types.string),
  height: types.maybeNull(types.string),
  width: types.maybeNull(types.string),
  backgroundColor: types.maybeNull(types.string),
  topMargin: types.maybeNull(types.string),
  bottomMargin: types.maybeNull(types.string),
  leftMargin: types.maybeNull(types.string),
  rightMargin: types.maybeNull(types.string),
  content: types.maybeNull(
    types.array(
      types.union(
        {
          dispatcher: (snapshot) => {
            if (snapshot && snapshot._type === "sitePageLink") {
              return SitePageLink;
            }
            if (snapshot && snapshot._type === "userFile") {
              return UserFile;
            }
            if (snapshot && snapshot._type === "localeBlock") {
              return Block;
            }
            if (snapshot && snapshot._type === "userUrl") {
              return UserFile;
            }
            if (snapshot && snapshot._type === "disciplineArea") {
              return Discipline;
            }
            if (snapshot && snapshot._type === "localizedImage") {
              return Image;
            }
            if (snapshot && snapshot._type === "eventsList") {
              return SiteEventsList;
            }
            if (snapshot && snapshot._type === "siteRow") {
              return SiteRow;
            }
            return Block;
          },
        },
        Block,
        Discipline,
        UserFile,
        SitePageLink,
        SiteRow,
        SiteEventsList
      )
    )
  ),
});

const SitePage = types.model({
  _id: types.identifier,
  shortId: types.maybeNull(types.string),
  addToMenu: false,
  menuOrder: types.maybeNull(types.number),
  name: types.maybeNull(types.string),
  rows: types.maybeNull(types.array(SiteRow)),
});

const SitePageId = types.model({
  shortId: types.identifier,
  sitePage: types.reference(SitePage),
});

const SitePages = types
  .model({
    sitePages: types.map(SitePage),
    loading: false,
    state: "done",
    sitePagesIds: types.map(SitePageId),
  })
  .views((self) => {
    return {
      get allSitePages() {
        return values(self.sitePages);
      },
      getSitePage(sitePageId) {
        return self.sitePages.get(sitePageId);
      },
      getSitePageByShortId(sitePageShortId) {
        const sitePageId = self.sitePagesIds.get(sitePageShortId);
        if (sitePageId) {
          return sitePageId.sitePage;
        }
        return null;
      },
      get addToMenuPages() {
        return self.allSitePages
          .filter((page) => page.addToMenu)
          .sort((prev, next) => {
            if (next.menuOrder < prev.menuOrder) {
              return 1;
            }
            return -1;
          });
      },
      get navigation() {
        const navigationAfter = [
          { path: "/members", key: "members" },
          { path: "/events", key: "events" },
          { path: "/newsletters", key: "newsletters" },
        ];

        const customNavigation = self.addToMenuPages.map((sitePage) => {
          return { path: `/sitePage/${sitePage._id}`, key: sitePage.name };
        });
        return customNavigation.concat(navigationAfter);
      },
    };
  })
  .actions((self) => {
    const fetchSitePages = flow(function* () {
      // <- note the star, this is a generator function!
      self.state = "pending";
      self.loading = true;
      const language = getParent(self).language;
      try {
        // ... yield can be used in async/await style
        const sitePagesArray = yield sanityClient.fetch(
          `*[_type == "sitePage"]
          {
            _id,
            shortId,
            addToMenu,
            menuOrder,
            "name": name.${language},
            
                "rows": rows[]
                {
                  _key, name, "color": color->color, "backgroundColor": color->backgroundColor, width, height, topMargin, bottomMargin, leftMargin, rightMargin,
                  "columns": columns[]
                    {
                      _key,
                      name,
                      "color": color->color,
                      "backgroundColor": color->backgroundColor,
                      width,
                      height,
                      topMargin,
                      bottomMargin,
                      leftMargin,
                      rightMargin,
                      "content": columnContent[]
                      {
                         _type == 'sitePageLink' => @{_key,_type,"name":title.${language}, "color": color->{color, backgroundColor}, 
                                                           "sitePageId":sitePage->_id, "sitePageShortId": sitePage->shortId},
                         _type == 'userFile' => @{_key, _type, "name": name.${language}, "color": color->{color, backgroundColor},
                                                  "url": file.${language}.asset->url, embedd},  
                         _type == 'userUrl' => @{_key, _type, "name": label.${language}, "color": color->{color, backgroundColor},
                                                  "url": url.${language}},                       
                        _type == 'localeBlock' => @{_key, _type, marginTop, marginRight, marginBottom, marginLeft, "color": color->color,
                                                 "backgroundColor": color->backgroundColor, "block": ${language}[]},
                        _type == 'disciplineArea' => @{_key, _type, "widgetType": ^.widgetType[0],"spanRight": ^.spanRight, "spanDown": ^.spanDown, "_id": discipline->_id ,
                                                       "key": discipline->key, "color": discipline->color->{color, backgroundColor}, "imageUrl": discipline->image.asset->url,
                                                       "name": discipline->name.${language}, "sitePageId": discipline->sitePage->_id, "userPageId": discipline->userPage->_id},
                        _type == 'localizedImage' => @{_key, _type, description, "url": photo.${language}.asset->url, 
                                                               width, height, "linkTo": linkTo[0].url.${language}},
                        _type == 'eventsList' => @{_key, _type, numberOfEvents, eventType},
                        _type == 'siteRow' => @{
                          _key, _type, name, "color": color->color, "backgroundColor": color->backgroundColor, width, height, topMargin, bottomMargin, leftMargin, rightMargin,
                          "columns": columns[]
                            {
                              _key,
                              name,
                              "color": color->color,
                              "backgroundColor": color->backgroundColor,
                              width,
                              height,
                              topMargin,
                              bottomMargin,
                              leftMargin,
                              rightMargin,
                              "content": columnContent[]
                              {
                                 _type == 'sitePageLink' => @{_key,_type,"name":title.${language}, "color": color->{color, backgroundColor}, 
                                                                   "sitePageId":sitePage->_id, "sitePageShortId": sitePage->shortId},
                                 _type == 'userFile' => @{_key, _type, "name": name.${language}, "color": color->{color, backgroundColor},
                                                          "url": file.${language}.asset->url, embedd},  
                                 _type == 'userUrl' => @{_key, _type, "name": label.${language}, "color": color->{color, backgroundColor},
                                                          "url": url.${language}},                       
                                _type == 'localeBlock' => @{_key, _type, marginTop, marginRight, marginBottom, marginLeft, "color": color->color,
                                                         "backgroundColor": color->backgroundColor, "block": ${language}[]},
                                _type == 'disciplineArea' => @{_key, _type, "widgetType": ^.widgetType[0],"spanRight": ^.spanRight, "spanDown": ^.spanDown, "_id": discipline->_id ,
                                                               "key": discipline->key, "color": discipline->color->{color, backgroundColor}, "imageUrl": discipline->image.asset->url,
                                                               "name": discipline->name.${language}, "sitePageId": discipline->sitePage->_id, "userPageId": discipline->userPage->_id},
                                _type == 'localizedImage' => @{_key, _type, description, "url": photo.${language}.asset->url, 
                                                                       width, height, "linkTo": linkTo[0].url.${language}},
                                _type == 'eventsList' => @{_key, _type, numberOfEvents, eventType},
                                
                              }
                            }
                        },
                      }
                    }
                }      
                 
          }[0...100]`
        );

        self.sitePages.clear();
        self.sitePagesIds.clear();
        sitePagesArray.forEach((element) => {
          const sitePage = self.sitePages.put(element);
          if (element.shortId) {
            self.sitePagesIds.put({
              shortId: element.shortId,
              sitePage: sitePage._id,
            });
          }
        });
        self.loading = false;
        self.state = "done";
      } catch (error) {
        // ... including try/catch error handling
        console.error("Failed to fetch site pages", error);
        self.loading = false;
        self.state = "error";
      }
    });

    return {
      fetchSitePages,
    };
  });

export default SitePages;
