/* eslint-disable react-hooks/exhaustive-deps */
import styles from "./webinars.module.scss";
// import { useNavigate } from "react-router-dom";
import { rootStore, useMst } from "../../models/root";
import { observer } from "mobx-react-lite";
import { useEffect } from "react";
// import { useParams } from "react-router-dom";

import YouTube from "react-youtube";

function Webinars({ chatType = "webinar", audienceType = "forBoth" }) {
  // const params = useParams();
  const { chatsStore } = useMst();
  // const navigate = useNavigate();
  const { fetchChats, getChats, loading } = chatsStore;
  const { language } = rootStore;

  useEffect(() => {
    fetchChats();
  }, []);
  useEffect(() => {
    fetchChats();
  }, [language]);

  if (loading) {
    return "loading";
  }
  return (
    <div className={styles.webinars}>
      {getChats(chatType, audienceType).map((chat) => {
        let videoId;
        if (chat.youtubeLink) {
          videoId = chat.youtubeLink.split("/").pop();
        }
        const opts = { height: "100%", width: "100%" };
        return (
          <div className={styles.video} key={chat._id}>
            <div className={styles.disciplines}>
              {(chat.disciplines || []).map((discipline) => {
                return (
                  <div
                    className={styles.image}
                    style={{ backgroundImage: `url("${discipline.imageUrl}")` }}
                  >
                    {/* <div className={styles.name}>{discipline.name}</div> */}
                  </div>
                );
              })}
            </div>
            <div className={styles.description}>{chat.description}</div>
            {videoId && (
              <YouTube className={styles.link} videoId={videoId} opts={opts} />
            )}
          </div>
        );
      })}
    </div>
  );
}

export default observer(Webinars);
