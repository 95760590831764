import styles from "./members.module.scss";
import countriesData from "./countries.json";
import Accordion from "../common/accordion/accordion";
import Flag from "react-flagkit";
import { useTranslation } from "react-i18next";

function Header(props) {
  return (
    <div className={styles.header}>
      <div className={styles.flag}>
        <Flag country={props.flag} />
      </div>
      <div className={styles.countryName}>{props.name}</div>
      <div className={styles.countryCode}>{`(${props.countryCode})`}</div>
      <div className={styles.fedName}>{props.fedName}</div>
    </div>
  );
}

function Members() {
  const { i18n } = useTranslation();
  const lang = i18n.language;
  return (
    <div className={styles.members}>
      {countriesData.countries.map((country) => {
        let name = country.name.en;
        if (lang === "es") {
          name = country.name.es;
        }
        const countryHeader = (
          <Header
            name={name}
            countryCode={country.code}
            flag={country.flag}
            fedName={country.fedName}
          />
        );

        return (
          <div key={`${country.code}accordiondiv`} className={styles.country}>
            <div className={styles.accordion}>
              <Accordion header={countryHeader}>
                {country.website && (
                  <a
                    href={country.website}
                    className={styles.website}
                    target="_blank"
                    rel="noreferrer"
                  >
                    {country.website}
                  </a>
                )}
              </Accordion>
            </div>
          </div>
        );
      })}
    </div>
  );
}

export default Members;
